import React from "react";
import "./styles.css";
const WhyMaha = ({ whyMahaRef, locale = "ar" }) => {
  const image = require("./whyMaha.png");
  const icon1 = require("./layers.png");
  const icon2 = require("./data.png");
  const icon3 = require("./puzzle.png");
  return (
    <div ref={whyMahaRef} className="whyMaha" id="why-hassala">
      <h2 style={{ direction: locale === "ar" ? "rtl" : "ltr" }}>
        {locale === "ar" ? "لماذا حصالة" : "Why Hassala"}
      </h2>
      <div className="whyMahaGrid">
        <div className="whyMahaImageBase">
          <img src={image} className="whyMahaImage" alt="About image" />
        </div>
        <div className="whyMahaIcons">
          <div
            className={
              locale === "ar" ? "whyMahaIconCard dir-rtl" : "whyMahaIconCard"
            }
          >
            <div className="whyMahaIconTitle whyMahaIconTitle-ar">
              <img
                src={icon1}
                className="whyMahaIcon"
                style={locale == "en" ? { marginRight: 5 } : { marginLeft: 0 }}
                alt="About image"
              />
              <h4>{locale === "ar" ? "تعزيز الوصول للعملاء" : "OUTREACH"}</h4>
            </div>
            <p>
              {locale === "ar"
                ? "وسعوا نطاق نشر التثقيف المالي من خلال حل رقمي جذّاب وسهل الاستخدام ومتاح للجميع."
                : "Scale up the distribution of your financial education with a digital solution that is fun, accessible and easy to use."}
            </p>
          </div>

          <div
            className={
              locale === "ar" ? "whyMahaIconCard dir-rtl" : "whyMahaIconCard"
            }
          >
            <div className="whyMahaIconTitle whyMahaIconTitle-ar">
              <img
                src={icon2}
                className="whyMahaIcon"
                style={locale == "en" ? { marginRight: 5 } : { marginLeft: 0 }}
                alt="About image"
              />
              <h4>{locale === "ar" ? "تحليلات البيانات" : "DATA"}</h4>
            </div>
            <p>
              {locale === "ar"
                ? "احصلوا على تحليلات فورية لتقييم احتياجات المتعلمين، واكتشاف الجوانب التي تستلزم تحسناً، وإثبات تأثير التثقيف المالي على عملائكم."
                : "Access real-time analytics to assess the needs of learners, identify knowledge gaps and demonstrate the impact of financial education on your clients."}
            </p>
          </div>

          <div
            className={
              locale === "ar" ? "whyMahaIconCard dir-rtl" : "whyMahaIconCard"
            }
          >
            <div className="whyMahaIconTitle whyMahaIconTitle-ar">
              <img
                src={icon3}
                className="whyMahaIcon"
                style={locale == "en" ? { marginRight: 5 } : { marginLeft: 0 }}
                alt="About image"
              />
              <h4>{locale === "ar" ? "تحليلات التسويق" : "MARKETING"}</h4>
            </div>
            <p>
              {locale === "ar"
                ? "احصلواعزّزوا فهم منتجاتكم وخدماتكم، وابنوا الثقة، وزيدوا الوعي بعلامتكم التجارية في أسواق جديدة ومع قاعدة عملاء أوسع."
                : "Enhance the understanding of your products and services, build trust and increase brand awareness with wider audiences."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMaha;
