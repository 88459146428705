import React from "react";
import "./Podium.css"; // Assuming we'll add the CSS in this file
import Lottie from "react-lottie";
import BADGE_ICONS from "../shared/topNavBar/badges";
import { Typography } from "@mui/material";
import Coin from "../../components/shared/topNavBar/coins.json";

// Reusable LottieBadge component
const LottieBadge = ({ animationData, size = "12vw" }) => (
  <Lottie
    style={{
      width: size,
      margin: "none",
    }}
    options={{
      loop: false,
      autoplay: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }}
    height={size}
    width={size}
    isStopped={true}
    isPaused={true}
  />
);

// Reusable CoinDisplay component
const CoinDisplay = ({ amount, size = "9vw" }) => (
  <div className="step-info">
    <Lottie
      style={{
        width: size,
        margin: "none",
      }}
      options={{
        loop: false,
        autoplay: false,
        animationData: Coin,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={size}
      width={size}
      isStopped={true}
      isPaused={true}
    />
    <Typography variant="h6" className="marhey">
      {amount}
    </Typography>
    <Typography variant="body1" className="marhey">
      ألف
    </Typography>
  </div>
);

const Podium = () => {
  return (
    <div className="podium-container">
      <div className="podium">
        {/* Second Place */}
        <div className="step-2">
          <CoinDisplay amount="19" />
          <div className="step">
            <LottieBadge animationData={BADGE_ICONS.silver} />
          </div>
        </div>

        {/* First Place */}
        <div className="step-1">
          <CoinDisplay amount="25" />
          <div className="step">
            <LottieBadge animationData={BADGE_ICONS.gold} />
          </div>
        </div>

        {/* Third Place */}
        <div className="step-3">
          <CoinDisplay amount="17" />
          <div className="step">
            <LottieBadge animationData={BADGE_ICONS.bronze} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Podium;
