import React from "react";
import "./styles.css";
const logo = require("./bank-illustration-transparent.png");

const Footer = ({ locale = "ar" }) => {
  const image = require("./footer3.png");
  return (
    <div className="footer">
      <div className="footerImageBase">
        <img className="footerImage" src={image} />
      </div>
      <div className="footerInfo">
        <div className="footerInfoBase">
          <div className="row">
            <img src={logo} className="footerLogo" />
            <div className="footerBaseInner">
              <div className="footerRow">
                <div
                  style={
                    locale === "ar"
                      ? { width: "100%", direction: "rtl" }
                      : {
                          width: "100%",
                          direction: "ltr",
                          textAlign: "left",
                          marginRight: "auto",
                        }
                  }
                >
                  <h3
                    className="contact_text"
                    style={
                      locale === "ar"
                        ? { direction: "rtl" }
                        : { direction: "ltr" }
                    }
                  >
                    {locale === "ar" ? "تواصل معنا" : "Contact"}
                  </h3>
                  <a
                    style={
                      locale === "ar"
                        ? { direction: "rtl" }
                        : {
                            direction: "ltr",
                            textAlign: "left",
                            marginRight: "auto",
                          }
                    }
                    className="footerCell email_text"
                    href="mailto:info@joinmaha.com"
                  >
                    info@hassala.app
                  </a>
                  <p
                    style={
                      locale === "ar"
                        ? { direction: "rtl" }
                        : {
                            direction: "ltr",
                            textAlign: "left",
                            marginRight: "auto",
                          }
                    }
                  >
                    {locale === "ar"
                      ? "171 شارع التحرير، القاهرة، مصر 11513"
                      : "The GrEEK Campus, 171 Tahrir Street, Cairo, Egypt 11513"}
                  </p>
                </div>
                <div className="footerCell">
                  <h5
                    style={{
                      direction: "ltr",
                      textAlign: "left",
                      marginRight: "auto",
                    }}
                  >
                    © {new Date().getFullYear()}, Hassala Technology. All Rights
                    Reserved.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
