import React from "react";
import "./styles.css";
import UseCasesCard from "./card";
const UseCases = ({ useCaseRef, locale = "ar" }) => {
  return (
    <div ref={useCaseRef} className="useCases" id="use-cases">
      <div className="useCasesBase">
        <h2 style={{ direction: locale === "ar" ? "rtl" : "ltr" }}>
          {locale === "ar" ? "حالات الاستخدام" : "Use cases"}
        </h2>

        <div className="useCasesGrid">
          <div className="useCaseImageBase">
            <UseCasesCard
              locale={locale}
              title={"Financial Institutions"}
              titleAr={"المؤسسات المالية"}
              descriptionAr={
                "توفر حصالة تدريباً على التثقيف المالي لزيادة الوعي وتحسين فهم عملاء المؤسسات المالية للمنتجات والخدمات المالية."
              }
              description={
                "Hassala provides financial litertacy training to raise awareness and improve the understanding of financial products and services to clients of financial institutions."
              }
            />
            <UseCasesCard
              locale={locale}
              title={"Development Organisations"}
              description={
                "Hassala assists impact-focused organisations and charities with financial literacy training to support their benificiaries and enable financial inclusion."
              }
              titleAr={"المنظمات التنموية"}
              descriptionAr={
                "حصالة تساعد و تدعم المنظمات الخيرية والمؤسسات التي تركز على التنمية الاجتماعية في تقديم تدريب على التثقيف المالي لدعم المستفيدين وتعزيز الشمول المالي."
              }
            />
            <UseCasesCard
              locale={locale}
              title={"Media Outlets"}
              description={
                "Hassala creates content for education-led campagins to promote financial literacy and access to finance in the community."
              }
              titleAr={"المنصات الإعلامية"}
              descriptionAr={
                "تُنتج حصالة محتوى لحملات توعوية تعليمية تهدف إلى تعزيز التثقيف المالي وتسهيل الوصول إلى الخدمات المالية لكل فرد في المجتمع ."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
