import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./expandable-circle.styles.css";
import DynamicLineCard from "../../pages/hassala/dotted";
import { ToastContainer, toast } from "react-toastify";

const ExpandableCircle = ({
  image,
  title,
  description,
  finishedTutorialsDict,
  tutorials,
  unlockedTutorials,
  finishedTutorials,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [tutorialPositions, setTutorialPositions] = useState([]);
  const tutorialRefs = useRef([]);

  const handleExpand = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const positions = tutorialRefs.current.map((tutorial) => {
        const circleRect = tutorial.closest(".circle").getBoundingClientRect();
        const tutorialRect = tutorial.getBoundingClientRect();

        return {
          x: tutorialRect.left - circleRect.left + tutorialRect.width / 2,
          y: tutorialRect.top - circleRect.top + tutorialRect.height / 2,
        };
      });
      setTutorialPositions(positions);
    }
  }, [isExpanded, tutorials]);

  // Calculate number of rows based on the number of tutorials
  const tutorialsPerRow = 2;
  const numRows = Math.ceil(tutorials?.length / tutorialsPerRow);

  // Calculate lastLineHalfWidth flag: true if the last row has only one tutorial
  const lastLineHalfWidth = tutorials?.length % tutorialsPerRow === 1;

  const newheight = Math.ceil(tutorials?.length / 2) * 55;

  // Find the first image with class 'item' and calculate its center
  const firstTutorialImage = document.querySelector(".item"); // First element with class 'item'
  const firstTutorialCenter = firstTutorialImage
    ? firstTutorialImage.getBoundingClientRect()
    : { left: 0, top: 0, width: 0, height: 0 };

  const startX = (firstTutorialCenter.left + firstTutorialCenter.width) / 2;
  const startY = (firstTutorialCenter.top + 0) / 2;

  return (
    <>
      <div className="expandable-circle">
        <div
          className="circle"
          style={{
            height: isExpanded ? `${80 + newheight}vw` : "80vw",
          }}
          onClick={handleExpand}
        >
          <div className="circle-content">
            <img src={image} className="logo" alt={title} />
            <div className="text-content">
              <h3 className="marhey">{title}</h3>
              <h5 className="desc_base tajawal">{description}</h5>
              <h4 className="finished-tutorials tajawal">
                خلصت {finishedTutorialsDict ? finishedTutorialsDict.number : 0}{" "}
                من {tutorials?.length} دروس
              </h4>
              {/* Custom SVG icon */}
              <svg
                className={`chevron ${isExpanded ? "expanded" : ""}`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.293 6.293a1 1 0 0 1 1.414 0L12 11.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414zm0 6a1 1 0 0 1 1.414 0L12 17.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414z"
                  fill="#0D0D0D"
                />
              </svg>
            </div>
          </div>
          {isExpanded && (
            <div className={`expanded-content ${isExpanded ? "open" : ""}`}>
              <div className="items-container">
                {tutorials.map((item, index) => (
                  <>
                    <div
                      key={item._id} // Ensure proper unique key
                      className={`item-base ${
                        tutorials?.length > 1 ? "" : "item-base-single"
                      }`}
                      ref={(el) => (tutorialRefs.current[index] = el)}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          (!unlockedTutorials.includes(item._id) &&
                            !finishedTutorials
                              .map((t) => t._id)
                              .includes(item._id)) ||
                          item.flashCards.length === 0
                        ) {
                          toast.error("الدرس ده مغلق", {
                            position: "top-right",
                          });
                        } else {
                          navigate(`/learn/${item._id}`, {
                            state: item,
                          });
                        }
                      }}
                    >
                      <img
                        className="item"
                        style={{
                          opacity: isExpanded ? 1 : 0,
                        }}
                        src={item.icon}
                        alt={title}
                      />
                      <div className="scrolling-container_2 tajawal">
                        {index + 1} الدرس
                      </div>
                      <div className="scrolling-container tajawal">
                        {item.name}
                      </div>
                    </div>
                    {index === 0 && (
                      <DynamicLineCard
                        numRows={numRows} // Dynamic number of rows
                        lastLineHalfWidth={lastLineHalfWidth} // Dynamically calculate lastLineHalfWidth
                      />
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ExpandableCircle;
