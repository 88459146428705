import axios from "axios";
import { baseUrl } from "./api";

export default class RewardsApis {
  static getRewards = async (token = null) => {
    const authToken = token ?? localStorage.getItem("token");
    return axios
      .get(`${baseUrl}/rewards`, {
        headers: { authorization: authToken },
      })
      .then((res) => {
        localStorage.setItem("rewards", JSON.stringify(res.data.rewards));
        return res.data.rewards
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
