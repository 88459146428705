import React, { useEffect, useState } from "react";
import {
  BottomNavigationBar,
  TopNavigationBar,
} from "../../components/index.js";
import { TutorialsApis, SummariesApis, RewardsApis } from "../../api/index.js";
import TutorialsPage from "../tutorials/tutorials.page.js";
import RewardsPage from "../rewards/rewards.page.js";
import SummariesPage from "../summaries/summaries.page.js";
import "./learn.styles.css";
import DynamicLineCard from "./dotted.js";

const HassalaPage = ({ type }) => {
  const [data, setData] = useState({
    rewards: [],
    tutorials: [],
    summaries: [],
  });
  const [userProgress, setUserProgress] = useState(null);

  const fetchData = async (type) => {
    try {
      let result;

      if (
        type === "tutorials" ||
        !data.tutorials ||
        data.tutorials.length === 0
      ) {
        result = await TutorialsApis.getTutorialsByTopic();
        localStorage.setItem(
          "tutorials",
          JSON.stringify(result.data.tutorials)
        );
        setData((prevData) => ({
          ...prevData,
          tutorials: result.data.tutorials,
        }));
      }
      if (type === "rewards" || !data.rewards || data.rewards.length === 0) {
        result = await RewardsApis.getRewards();
        setData((prevData) => ({ ...prevData, rewards: result }));
      }
      if (
        type === "summaries" ||
        !data.summaries ||
        data.summaries.length === 0
      ) {
        result = await SummariesApis.getSummaries();
        setData((prevData) => ({ ...prevData, summaries: result }));
      }
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  };

  useEffect(() => {
    const storedData = {
      tutorials: JSON.parse(localStorage.getItem("tutorials")) || [],
      rewards: JSON.parse(localStorage.getItem("rewards")) || [],
      summaries: JSON.parse(localStorage.getItem("summaries")) || [],
    };
    const storedProgress = localStorage.getItem("userProgress");

    setData(storedData);
    setUserProgress(JSON.parse(storedProgress));

    fetchData(type); // Fetch data on component mount based on the type
  }, [type]);

  // Scroll to the top of the page when the component is first mounted
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const renderChildPage = () => {
    switch (type) {
      case "tutorials":
        return (
          <TutorialsPage listing={data.tutorials} userProgress={userProgress} />
        );
      case "rewards":
        return <RewardsPage rewards={data.rewards} />;
      case "summaries":
        return <SummariesPage summaries={data.summaries} />;
      case "dotted":
        return <DynamicLineCard numRows={5} lastLineHalfWidth={true} />;

      default:
        return null;
    }
  };

  return (
    <div className="learn-page">
      <TopNavigationBar />
      <div className={`page-container ${type}-active`}>{renderChildPage()}</div>
      <BottomNavigationBar />
    </div>
  );
};

export default HassalaPage;
