import React, { useEffect, useState } from "react";
import { Box, Slide, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar } from "../../components";
import PointsCard from "./points.card";
import MedalsCard from "./medals.card";
import StreakTracker from "./streak";
import DrawCountdown from "./draw.countdown";
import SettingsMenu from "../../components/shared/topNavBar/SettingsMenu";
import { getColor } from "../../utils";
import DialogContentComponent from "./DialogContentComponent";
import { FiX } from "react-icons/fi";

const SlideUpTransition = React.forwardRef(function SlideUpTransition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const totalPoints = localStorage.getItem("totalPoints");
  const [userBadge, setUserBadge] = useState("blue");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  useEffect(() => {
    const badge = getColor(totalPoints);
    setUserBadge(badge);
  }, [totalPoints]);

  // Scroll to the top of the page when the component is first mounted
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const handleButtonClick = (content) => {
    setDialogContent(content);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const getNextFriday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Get the current day of the week (0 - 6, where 0 is Sunday, 6 is Saturday)
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7; // Calculate how many days until next Friday

    // If today is already Friday, we set the next Friday as a week later
    if (daysUntilFriday === 0) {
      return new Date(today.setDate(today.getDate() + 7)); // Add 7 days to make it next week’s Friday
    } else {
      return new Date(today.setDate(today.getDate() + daysUntilFriday)); // Set to next Friday
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          right: 0,
          padding: "10px",
        }}
      >
        <SettingsMenu
          color="white"
          anchorEl={anchorEl}
          open={open}
          handleMenuClick={(e) => setAnchorEl(e.currentTarget)}
          handleMenuClose={() => setAnchorEl(null)}
          navigate={navigate}
        />
      </div>
      <DrawCountdown endDate={getNextFriday()} />
      <p className="streak-message streak-message2 tajawal">فاضل على السحب</p>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <PointsCard points={totalPoints} />
          <MedalsCard badge={userBadge} />
        </Box>
        <StreakTracker />
        <Box className="bottom-buttons-container" sx={{ mt: 4 }}>
          <button
            className="bottom-button"
            onClick={() => handleButtonClick("HOW_TO_WIN")}
          >
            <span className="button-arrow tajawal">{"<"}</span>
            <span className="button-text tajawal">ازاي تكسب</span>
          </button>
          <button
            className="bottom-button-2"
            onClick={() => handleButtonClick("PAST_WINNERS")}
          >
            <span className="button-arrow tajawal">{"<"}</span>
            <span className="button-text tajawal">فائزين سابقين</span>
          </button>
        </Box>

        {/* Dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          TransitionComponent={SlideUpTransition}
          PaperProps={{
            style: {
              borderRadius: "16px",
              padding: "20px",
              bottom: 0,
              position: "absolute",
              margin: 0,
            },
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="popup-header">
                <FiX
                  style={{
                    color: "#305F72",
                    position: "absolute",
                    left: "7.5vw",
                    cursor: "pointer",
                    backgroundColor: "#ebedf5",
                    borderRadius: 100,
                    padding: 3,
                  }}
                  size={"5vw"}
                  onClick={closeDialog}
                />
              </div>
              <span className="dialog-title marhey">
                {dialogContent === "PAST_WINNERS"
                  ? "فائزين سابقين"
                  : "ازاي تكسب"}
              </span>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentComponent content={dialogContent} />
          </DialogContent>
        </Dialog>
      </Box>
      <BottomNavigationBar />
    </>
  );
};

export default UserPage;
