import React from "react";
import "./styles.css";
const Info = ({ locale = "ar" }) => {
  return (
    <>
      <div className={locale === "ar" ? "curve curve-ar" : "curve"}></div>
      <div className="info">
        <div className="infoText">
          <h2 style={{fontSize:'1.3em'}}>
            {locale === "ar"
              ? "منصة للتثقيف المالي لبناء روابط أقوى مع عملائكم"
              : "A financial content platform to engage with your community"}
          </h2>
        </div>
        <div className="infoText">
          <p
            style={
              locale === "ar"
                ? { direction: "rtl", textAlign: "right" }
                : { direction: "ltr", textAlign: "left" }
            }
          >
            {locale === "ar"
              ? "استفيدوا من التكنولوجيا لتمكين عملائكم من النجاح عبر تزويدهم بالمعرفة والمهارات التي يحتاجونها لتحقيق أهدافهم المالية."
              : "Leverage technology to set your clients up for success by giving them the knowledge and skills they need to reach their financial goals."}
          </p>
        </div>
      </div>
    </>
  );
};

export default Info;
