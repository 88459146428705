import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AuthReducer } from "../../redux/actions";
import "./login.styles.css";
import { LoginApis, TutorialsApis } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import illustration from "../bank-illustration-transparent.png"; // example import of an illustration
import LoginStack from "../../components/loginStack/loginStack";
import { IoLogOutOutline } from "react-icons/io5";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";

import ActivationCodeStack from "../../components/loginStack/activationCodeStack";
import RegisterationStack from "../../components/loginStack/registerationCodeStack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordStack from "../../components/loginStack/forgotPasswordStack";
import NewPasswordStack from "../../components/loginStack/newPasswordStack";

const LoginPage = ({ forgotPasswordFlag = false }) => {
  const { new_password_email: resetPasswordEmail } = useParams(); // Access the id parameter from the URL

  const [registerPayload, setRegisterPayload] = useState({
    email: "",
    password: "",
    mobile: "",
    confirmPassword: "",
  });
  const [passwordPayload, setPasswordPayload] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formCard, setFormCard] = useState("login");

  const [mobile, setMobile] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const authResult = await LoginApis.login({ password, mobile });
      const progressResult = await TutorialsApis.getFinishedTutorials();
      dispatch(AuthReducer.loginUser(authResult.data));
      dispatch(AuthReducer.setUserProgress(progressResult.data));
      navigate("/user");
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        toast.error("رقم الموبايل او كلمة المرور غير صحيحة", {
          position: "top-right",
        });
      } else {
        toast.error("حدث خطاء ما", {
          position: "top-right",
        });
      }
    }
    setIsLoading(false);
  };

  const handleSubmitActivationCode = async (e) => {
    setIsLoading(true);
    try {
      await LoginApis.verifyActivationCode({ input: activationCode });
      toast.success("تم تأكيد رمز التفعيل، برجاء أدخال باقي البيانات", {
        position: "top-right",
      });
      handleNavigate("registeration");
    } catch (error) {
      toast.error("رمز التفعيل غير صحيح", {
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const handleRegisteration = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (
        registerPayload.password !== registerPayload.confirmPassword ||
        registerPayload.password.length < 8
      ) {
        toast.error("كلمة المرور غير متطابقة", {
          position: "top-right",
        });
        setIsLoading(false);
        return;
      }

      await LoginApis.registerUser({
        mobile: registerPayload.mobile,
        password: registerPayload.password,
        activationCode: activationCode,
        email: registerPayload.email,
      });
      toast.success("تم إنشاء الحساب بنجاح، برجاء تسجيل الدخول", {
        position: "top-right",
      });

      handleNavigate("login");
    } catch (error) {
      toast.error("حدث خطأ ما", {
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (
        passwordPayload.newPassword !== passwordPayload.confirmPassword ||
        passwordPayload.newPassword.length < 8
      ) {
        toast.error("كلمة المرور غير متطابقة", {
          position: "top-right",
        });
        setIsLoading(false);
        return;
      }

      await LoginApis.updatePassword({
        password: passwordPayload.newPassword,
        email: resetPasswordEmail,
      });
      toast.success("تم تغيير كلمة السر بنجاح، برجاء تسجيل الدخول", {
        position: "top-right",
      });

      handleNavigate("login");
    } catch (error) {
      toast.error("حدث خطأ ما", {
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const handleNavigate = (target) => {
    setFormCard(target);
  };

  const handleForgotPassword = async (e) => {
    setIsLoading(true);
    try {
      const response = await LoginApis.forgotPassword({
        email: forgotPasswordEmail,
      });
      toast.success(
        "تم تأكيد رمز التفعيل، برجاء أدخال باقي البيانابعتنا لك بريد إلكتروني فيه تعليمات عشان تعيد تعيين كلمة السر بتاعتك",
        {
          position: "top-right",
        }
      );
    } catch (error) {
      toast.error("حدث خطأ ما", {
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const renderForm = () => {
    if (formCard === "login" && !forgotPasswordFlag) {
      return (
        <LoginStack
          handleSubmit={handleSubmit}
          mobile={mobile}
          password={password}
          setPassword={setPassword}
          isLoading={isLoading}
          setMobile={setMobile}
          handleNavigate={handleNavigate}
        />
      );
    } else if (formCard === "forgotPassword" && !forgotPasswordFlag) {
      return (
        <ForgotPasswordStack
          handleSubmit={handleForgotPassword}
          forgotPasswordEmail={forgotPasswordEmail}
          setForgotPasswordEmail={setForgotPasswordEmail}
          isLoading={isLoading}
          setMobile={setMobile}
          handleNavigate={handleNavigate}
        />
      );
    } else if (formCard === "activationCode" && !forgotPasswordFlag) {
      return (
        <ActivationCodeStack
          handleSubmit={handleSubmitActivationCode}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          isLoading={isLoading}
          setMobile={setMobile}
          handleNavigate={handleNavigate}
        />
      );
    } else if (formCard === "registeration" && !forgotPasswordFlag) {
      return (
        <RegisterationStack
          handleSubmit={handleRegisteration}
          payload={registerPayload}
          setPayload={setRegisterPayload}
          isLoading={isLoading}
        />
      );
    } else if (forgotPasswordFlag) {
      return (
        <NewPasswordStack
          handleSubmit={handlePasswordReset}
          payload={passwordPayload}
          setPayload={setPasswordPayload}
          isLoading={isLoading}
        />
      );
    }
  };

  return (
    <>
      <Container maxWidth="md" className="login-base">
        <button
          className="partner_base"
          onClick={() => {
            localStorage.setItem("locale", "ar");
            navigate("/info");
          }}
        >
          <Typography
            variant="body1"
            component="h6"
            className="marhey"
            color="white"
          >
            إشترك معنا
          </Typography>
          <IoLogOutOutline size={"2rem"} className="partner_logo" />
        </button>
        {formCard !== "login" && (
          <button className="back_base" onClick={() => handleNavigate("login")}>
            <HiOutlineChevronDoubleLeft
              size={"2rem"}
              className="partner_logo"
            />
          </button>
        )}
        <Box
          component="img"
          src={illustration}
          alt="Login Illustration"
          sx={{
            width: "100%",
            height: isMobile ? "150px" : "500px",
            marginBottom: isMobile ? "-5vh" : "-5vh",
            marginTop: isMobile ? "-5vh" : "-5vh",
            objectFit: "contain",
          }}
        />
        {renderForm()}
      </Container>
      <ToastContainer />
    </>
  );
};

export default LoginPage;
