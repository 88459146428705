import React from "react";
import "./user.styles.css"; // Styles for MedalsCard
import { Typography } from "@mui/material";
import BADGE_ICONS from "../../components/shared/topNavBar/badges";
import Lottie from "react-lottie";

const MedalsCard = ({ badge }) => {
  const badges = {
    gold: "ذهبية",
    silver: "فضية",
    blue: "أزرق",
    bronze: "برونزية",
  };
  return (
    <div className="points-card">
      <div className="card-header">
        <div className="lmao">
          <Lottie
            style={{
              width: "13.5vw",
              margin: "none",
            }}
            options={{
              loop: false,
              autoplay: false,
              animationData: BADGE_ICONS[badge],
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"13.5vw"}
            width={"13.5vw"}
            isStopped={true}
            isPaused={true}
          />
          <div className="points-value">ميداليات</div>
        </div>
        <div>
          <Typography
            variant="body1"
            dir="rtl"
            style={{ fontSize: "1.5rem" }}
            color="text.secondary"
            fontWeight={"600"}
            className="marhey"
          >
            {badges[badge]}
          </Typography>
        </div>
      </div>
      <ul className="medals-list">
        <li>
          <span className="item-value tajawal">برونزية</span>
          <span className="item-label tajawal" dir="rtl">
            250 نقطة لفرصة الفوز ب1000ج
          </span>
        </li>
        <li>
          <span className="item-value tajawal">فضية</span>
          <span className="item-label tajawal" dir="rtl">
            500 نقطة لفرصة الفوز ب2000ج
          </span>
        </li>
        <li>
          <span className="item-value tajawal">دهبية</span>
          <span className="item-label tajawal" dir="rtl">
            750 نقطة لفرصة الفوز ب3000ج
          </span>
        </li>
      </ul>
    </div>
  );
};

export default MedalsCard;
