import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Collapse } from "@mui/material";
import { FiSettings } from "react-icons/fi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import InfoModal from "./InfoModal"; // Import the modal
import { PrivacyPolicyComponent, TermsOfServiceComponent } from "../../../content"; // Import content
import "./topNavbar.styles.css"; // CSS file for styles

const SettingsMenu = ({
  anchorEl,
  open,
  handleMenuClick,
  handleMenuClose,
  navigate,
  color = "",
}) => {
  const [settingsOpen, setSettingsOpen] = useState(false); // State to manage accordion for settings
  const [infoOpen, setInfoOpen] = useState(false); // State to manage accordion for important info
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal
  const [modalHeading, setModalHeading] = useState(""); // Store the modal heading
  const [modalContent, setModalContent] = useState(""); // Store the modal content

  const toggleSettingsMenu = () => {
    setSettingsOpen((prev) => !prev);
  };

  const toggleInfoMenu = () => {
    setInfoOpen((prev) => !prev);
  };

  const openModal = (heading, content) => {
    setModalHeading(heading);
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <FiSettings color={color} size={30} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          width: "100vw", // Set a fixed width for the menu
        }}
      >
        {/* Settings Menu */}
        <MenuItem onClick={toggleSettingsMenu} className="settings-menu-item">
          <div className="menu-item-content">
            <img
              src={require("./settings_preferences.png")}
              alt="settings icon"
              className="menu-icon"
            />
            إعدادات
          </div>
          {settingsOpen ? <FaChevronUp /> : <FaChevronDown />}
        </MenuItem>

        <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
          <div>
            <MenuItem
              onClick={() => navigate("/settings/email")}
              className="menu-item"
            >
              غير البريد الإلكتروني
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/settings/mobile")}
              className="menu-item"
            >
              غير رقم الهاتف
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/settings/password")}
              className="menu-item"
            >
              غير كلمة السر
            </MenuItem>
          </div>
        </Collapse>

        {/* Important Info Menu */}
        <MenuItem onClick={toggleInfoMenu} className="settings-menu-item">
          <div className="menu-item-content">
            <img
              src={require("./settings_info.png")}
              alt="info icon"
              className="menu-icon"
            />
            معلومات هامة
          </div>
          {infoOpen ? <FaChevronUp /> : <FaChevronDown />}
        </MenuItem>

        <Collapse in={infoOpen} timeout="auto" unmountOnExit>
          <div>
            <MenuItem
              onClick={() => openModal("سياسية الخصوصية", PrivacyPolicyComponent)}
              className="menu-item"
            >
              سياسية الخصوصية
            </MenuItem>
            <MenuItem
              onClick={() =>
                openModal("شروط استخدام حصالة", TermsOfServiceComponent)
              }
              className="menu-item"
            >
              شروط استخدام حصالة
            </MenuItem>
          </div>
        </Collapse>

        {/* Logout */}
        <MenuItem
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
          className="settings-menu-item"
        >
          <div className="menu-item-content">
            <img
              src={require("./settings_logout.png")}
              alt="logout icon"
              className="menu-icon"
            />
            تسجيل الخروج
          </div>
        </MenuItem>
      </Menu>

      {/* Info Modal */}
      <InfoModal
        open={modalOpen}
        onClose={closeModal}
        heading={modalHeading}
        content={modalContent}
      />
    </>
  );
};

export default SettingsMenu;
