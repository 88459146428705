import React, { useState, useEffect } from "react";

const DynamicLineCard = ({ numRows, lastLineHalfWidth, startX, startY }) => {
  const [svgHeight, setSvgHeight] = useState(0);
  let x = startX || 70; // Use the passed startX or default to 10
  let y = startY || 220; // Use the passed startY or default to 20
  let path = `M${x},${y}`; // Initialize the SVG path
  let direction = "right"; // Direction for drawing horizontal lines

  const cardWidth = 285;
  const lineLength = cardWidth - 20;
  let prevLineLength = lineLength;
  let maxY = y;
  const curveRadiusLeft = 160;
  const curveRadiusRight = 50;

  let rightDirectionRows = 0;

  const drawHorizontalLine = (currentLineLength) => {
    if (direction === "right") {
      let delta = currentLineLength;
      delta =
        rightDirectionRows === numRows - 1 && !lastLineHalfWidth
          ? delta - 70
          : delta;

      x = 10 + delta;
      path += ` H${x}`;
      direction = "left";
      rightDirectionRows++;
    } else {
      let delta = prevLineLength - currentLineLength + 20;
      x = 10 + delta;
      path += ` H${x}`;
      direction = "right";
    }
    prevLineLength = currentLineLength;
  };

  const drawVerticalLine = () => {
    // Define curve parameters
    const startCurveX = x;
    const startCurveY = y;
    const controlPoint1X = direction === "right" ? x - 15 : x + 15;
    const controlPoint1Y = direction === "right" ? y : y;
    // const controlPoint1Y = y + 15;
    const controlPoint2X = direction === "right" ? x - 15 : x + 15;
    const controlPoint2Y = direction === "right" ? y + 15 : y + 15;

    const verticalLineLength = direction === "right" ? 50 : curveRadiusLeft;

    // Start drawing the vertical line
    path += ` C${startCurveX},${startCurveY} ${controlPoint1X},${controlPoint1Y} ${controlPoint2X},${controlPoint2Y}`;

    // Update y position for vertical line
    y += verticalLineLength;

    const endCurveY = y - 15;
    const endPointX = direction === "right" ? x : x - 15;
    const endPointY = y;
    // Draw vertical line and second curve
    path += ` V${y - 25}`; // The V command goes vertically
    path += ` C${controlPoint2X},${endCurveY} ${controlPoint2X},${endPointY} ${endPointX},${endPointY}`;

    // Update maxY for proper SVG height
    maxY = Math.max(maxY, y);
  };

  // Loop for drawing lines...
  for (let i = 0; i < numRows * 2; i++) {
    let currentLineLength = prevLineLength;

    if (
      lastLineHalfWidth &&
      direction === "right" &&
      rightDirectionRows === numRows - 1
    ) {
      currentLineLength = prevLineLength / 2;
    }

    drawHorizontalLine(currentLineLength);

    if (rightDirectionRows < numRows) {
      const curveRadius =
        direction === "right" ? curveRadiusRight : curveRadiusLeft;
      drawVerticalLine();
    }
  }

  useEffect(() => {
    setSvgHeight(maxY + 20);
  }, [maxY]);

  const translateYValue = numRows < 3 ? (numRows === 1 ? -70 : -35) : -25;
  return (
    <svg
      style={{
        transform:
          numRows === 1
            ? `translate(50px, ${translateYValue}%)`
            : `translateY(${translateYValue}%)`,
        position: "absolute",
      }}
      width="100%"
      height={svgHeight}
      viewBox={`0 0 300 ${svgHeight}`}
    >
      <path
        d={path}
        stroke="#BFBFBF" // Your custom hex color
        fill="transparent"
        strokeWidth="2"
        strokeDasharray="3,10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DynamicLineCard;
