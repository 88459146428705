import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  USER_PROGRESS,
} from "../actions/types";

const initialState = {
  isLoggedIn: false,
  user: null,
  loading: false,
  error: null,
  token: null,
  userProgress: null
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        loading: false,
        error: null,
        token: payload.token
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loading: false,
        error: null,
      };
    case USER_PROGRESS:
      return {
        ...state,
        userProgress: payload
      }
    default:
      return state;
  }
}
