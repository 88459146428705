import React from "react";
import Lottie from "react-lottie";
import Coin from "./coins.json";
import CountUp from "react-countup";
import "./topNavbar.styles.css";

const PointsDisplay = ({ totalPoints, addedPoints }) => {
  return (
    <div className="points-display">
      <Lottie
        style={{ width: "7vw", transform: 'translateY(0.25vh)' }}
        options={{
          loop: true,
          autoplay: true,
          animationData: Coin,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"7vw"}
        width={"7vw"}
      />
      <CountUp
        delay={4}
        duration={1.5}
        style={{ fontSize: "4vw", fontWeight: "700", color: "#305F72" }}
        start={totalPoints - addedPoints}
        end={totalPoints}
      />
    </div>
  );
};

export default PointsDisplay;
