import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { BottomNavigationBar, TopNavigationBar } from "../../components";
import { SettingsApis } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangeEmail = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); // New password state
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError("يرجى إدخال عنوان بريد إلكتروني صالح");
      return; // Exit if email is not valid
    } else {
      setError("");
    }

    setLoading(true); // Start loader

    try {
      // Sending both email and password in the API payload
      await SettingsApis.editSettings({ email, password });
      toast.success("تم تحديث البريد الإلكتروني بنجاح", {
        position: "top-right",
      });
    } catch (err) {
      toast.error("فشل تحديث البريد الإلكتروني. حاول مرة أخرى.", {
        position: "top-right",
      });
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <>
      <TopNavigationBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          padding: 2,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            borderRadius: 2,
            width: "90%", // Responsive width
            maxWidth: 400, // Max width for larger screens
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{ marginBottom: 2 }}
            className="marhey"
          >
            غير البريد الإلكتروني
          </Typography>
          <TextField
            label="البريد الإلكتروني"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())} // Convert to lowercase
            error={!!error}
            helperText={error}
            fullWidth
            margin="normal"
          />
          <TextField
            label="كلمة السر"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Set password state
            fullWidth
            margin="normal"
          />
          <Button
            className="current-button"
            onClick={handleSubmit}
            style={{ margin: "auto", width: "80%", position: "relative" }}
          >
            {loading ? (
              <CircularProgress size={24} color="warning" />
            ) : (
              <Typography
                variant="body1"
                fontWeight={"700"}
                color="white"
                style={{ transform: "translateY(3px)" }}
                className="tajawal"
              >
                تأكيد
              </Typography>
            )}
          </Button>
        </Paper>
      </Box>
      <BottomNavigationBar />
      <ToastContainer />
    </>
  );
};

export default ChangeEmail;
