import React from "react";
import Lottie from "react-lottie";
import BADGE_ICONS from "./badges";
import "./topNavbar.styles.css";

const UserBadge = ({ userBadge }) => {
  return (
    <div className="user-badge">
      <Lottie
        style={{ width: "7vw" }}
        options={{
          loop: true,
          autoplay: true,
          animationData: BADGE_ICONS[userBadge],
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"7vw"}
        width={"7vw"}
      />
    </div>
  );
};

export default UserBadge;
