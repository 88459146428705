import axios from "axios";
import { baseUrl } from "./api";

export default class TutorialsApis {
  static getTopics = async (token = null) => {
    const authToken = token ?? localStorage.getItem("token");
    return axios
      .get(`${baseUrl}/topics`, {
        headers: { authorization: authToken },
      })
      .catch((err) => {
        console.log(err);
      });
  };

  static getTutorialsByTopic = async (token = null) => {
    const authToken = token ?? localStorage.getItem("token");

    return axios
      .get(`${baseUrl}/tutorials/listing`, {
        headers: { authorization: authToken },
      })
      .catch((err) => {
        console.log(err);
      });
  };

  static getFinishedTutorials = async (token = null) => {
    try {
      const authToken = token ?? localStorage.getItem("token");
      return axios
        .get(`${baseUrl}/users/finished/tutorials`, {
          headers: { authorization: authToken },
        })
        .then((res) => {
          localStorage.setItem("userProgress", JSON.stringify(res.data));
          return res;
        });
    } catch (error) {
      console.log(error);
    }
  };

  static finishTutorial = async (payload, token = null) => {
    try {
      const authToken = token ?? localStorage.getItem("token");
      return axios
        .post(`${baseUrl}/users/progress/tutorial/finish`, payload, {
          headers: { authorization: authToken },
        })
        .then((res) => {
          localStorage.setItem("answers", JSON.stringify(res.data.userAnswers));
          localStorage.setItem(
            "totalPoints",
            JSON.stringify(res.data.totalPoints)
          );
          localStorage.setItem(
            "finishedTutorials",
            JSON.stringify(res.data.finishedTutorials)
          );
          localStorage.setItem("userProgress", JSON.stringify(res.data));
          return res;
        });
    } catch (error) {
      console.log(error);
    }
  };
}
