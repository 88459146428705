import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { FiX } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import CardStack from "../../components/cardStack/cardStack";
import { GamesApi } from "../../api";

// Utility functions defined outside the component
const randomLetter = () => {
  // Generate a random index between 0 and 27 for the 28 Arabic letters
  const randomIndex = Math.floor(Math.random() * 26);
  // Return the corresponding Arabic letter using its Unicode value
  return String.fromCharCode(0x0621 + randomIndex);
};

const generateEmptyGrid = (size) => {
  return Array(size)
    .fill(null)
    .map(() => Array(size).fill(""));
};

const placeWordsInGrid = (grid, words) => {
  const directions = [
    { x: -1, y: 0 }, // Horizontal (left to right)
    { x: 0, y: 1 }, // Vertical (top to bottom)
    { x: 1, y: 1 }, // Diagonal (down-right)
  ];

  words.forEach((word) => {
    const wordLength = word.length; // Get the length of the word
    let placed = false;

    let attempts = 0;
    const maxAttempts = 100; // Prevent infinite loop

    while (!placed && attempts < maxAttempts) {
      attempts += 1;
      const dir = directions[Math.floor(Math.random() * directions.length)];
      const startX = Math.floor(
        Math.random() * (grid.length - (dir.x === 0 ? 0 : wordLength))
      );
      const startY = Math.floor(
        Math.random() * (grid.length - (dir.y === 0 ? 0 : wordLength))
      );

      let canPlace = true;
      for (let i = 0; i < wordLength; i++) {
        const x = startX + i * dir.x;
        const y = startY + i * dir.y;
        // Check if the position is within bounds and empty
        if (
          x < 0 ||
          x >= grid.length ||
          y < 0 ||
          y >= grid.length ||
          grid[x][y] !== ""
        ) {
          canPlace = false;
          break;
        }
      }

      if (canPlace) {
        for (let i = 0; i < wordLength; i++) {
          const x = startX + i * dir.x;
          const y = startY + i * dir.y;
          grid[x][y] = word[i]; // Place the word in the grid
        }
        placed = true;
      }
    }

    if (!placed) {
      console.warn(`Could not place the word: ${word}`);
    }
  });

  return grid;
};

const fillEmptySpaces = (grid) => {
  return grid.map((row) =>
    row.map((cell) => (cell === "" ? randomLetter() : cell))
  );
};

// WordSearch Component
const WordSearch = ({
  words = [
    // "مال",
    // "بنك",
    // "اقتصاد",
    // "عائد",
    "الفائدة",
    "سعر",
    // "سوق",
    // "حساب",
    // "ميزانية",
  ],
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isFinished } = location.state;

  const gridSize = 9; // Define the size of the grid
  const [grid, setGrid] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [foundWordCells, setFoundWordCells] = useState([]); // New state to track all found cells
  const [message, setMessage] = useState("");

  // Ref to the table element for touch position calculations
  const tableRef = useRef(null);

  // Memoize words to prevent unnecessary re-renders
  const stableWords = useMemo(
    () => words.map((word) => word.toUpperCase()),
    [words]
  );
useEffect(() => {
  window.scrollTo(0, 1);
}, []);
  const flipGrid = (grid) => {
    // Flip the grid vertically and horizontally
    return grid.map((row) => [...row].reverse()).reverse();
  };

  const generateNewGrid = () => {
    let emptyGrid = generateEmptyGrid(gridSize);
    let gridWithWords = placeWordsInGrid(emptyGrid, stableWords);
    let filledGrid = fillEmptySpaces(gridWithWords);
    // Flip the grid both vertically and horizontally
    let flippedGrid = flipGrid(filledGrid);

    setGrid(flippedGrid);
    setFoundWords([]);
    setFoundWordCells([]); // Reset found word cells
    setMessage("");
  };

  useEffect(() => {
    generateNewGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unified handler for starting selection (mouse or touch)
  const handleStartSelection = (x, y) => {
    if (foundWords.length === stableWords.length) return; // All words found
    setIsSelecting(true);
    setSelectionStart({ x, y });
    setSelectedCells([{ x, y }]);
    setMessage("");
  };

  // Helper function to check if a cell is part of any found word
  const isCellFound = (x, y, grid, foundWords, gridSize) => {
    return foundWords.some((word) => {
      const wordLetters = word.split("");
      const directions = [
        { x: 1, y: 0 }, // Horizontal
        { x: 0, y: 1 }, // Vertical
        { x: 1, y: 1 }, // Diagonal down-right
        { x: 1, y: -1 }, // Diagonal up-right
      ];
      for (let dir of directions) {
        for (let i = 0; i < wordLetters.length; i++) {
          const startX = x - i * dir.x;
          const startY = y - i * dir.y;
          if (
            startX >= 0 &&
            startX < gridSize &&
            startY >= 0 &&
            startY < gridSize &&
            grid[startX][startY] === wordLetters[i].toUpperCase()
          ) {
            let found = true;
            for (let j = 0; j < wordLetters.length; j++) {
              const checkX = startX + j * dir.x;
              const checkY = startY + j * dir.y;
              if (
                grid[checkX] &&
                grid[checkX][checkY] !== wordLetters[j].toUpperCase()
              ) {
                found = false;
                break;
              }
            }
            if (found) return true;
          }
        }
      }
      return false;
    });
  };

  // Unified handler for updating selection (mouse or touch)
  const handleUpdateSelection = (x, y) => {
    if (!isSelecting || !selectionStart) return;

    // Check if the cell is already part of a found word
    if (isCellFound(x, y, grid, foundWords, gridSize)) return;

    const newEnd = { x, y };
    const start = selectionStart;

    const deltaX = newEnd.x - start.x;
    const deltaY = newEnd.y - start.y;

    // Determine the direction
    let direction = { x: 0, y: 0 };
    if (deltaX === 0 && deltaY === 0) {
      // Same cell
      direction = { x: 0, y: 0 };
    } else if (deltaX === 0) {
      // Vertical
      direction = { x: 0, y: deltaY > 0 ? 1 : -1 };
    } else if (deltaY === 0) {
      // Horizontal
      direction = { x: deltaX > 0 ? 1 : -1, y: 0 };
    } else if (Math.abs(deltaX) === Math.abs(deltaY)) {
      // Diagonal
      direction = {
        x: deltaX > 0 ? 1 : -1,
        y: deltaY > 0 ? 1 : -1,
      };
    } else {
      // Not a straight line
      return;
    }

    // Calculate the new selection cells based on direction
    const cells = [];
    let currentX = start.x;
    let currentY = start.y;

    while (
      (direction.x !== 0 && currentX !== newEnd.x) ||
      (direction.y !== 0 && currentY !== newEnd.y)
    ) {
      // Prevent adding cells that are part of found words
      if (isCellFound(currentX, currentY, grid, foundWords, gridSize)) {
        break;
      }

      cells.push({ x: currentX, y: currentY });
      currentX += direction.x;
      currentY += direction.y;

      // Prevent out-of-bounds
      if (
        currentX < 0 ||
        currentX >= gridSize ||
        currentY < 0 ||
        currentY >= gridSize
      ) {
        break;
      }
    }

    // Include the end cell if it's not part of a found word
    if (!isCellFound(currentX, currentY, grid, foundWords, gridSize)) {
      cells.push({ x: currentX, y: currentY });
    }

    setSelectedCells(cells);
  };

  // Unified handler for ending selection (mouse or touch)
  const handleEndSelection = () => {
    if (!isSelecting || !selectionStart) return;
    setIsSelecting(false);

    if (selectedCells.length === 0) return;

    // Extract the word from the grid
    const selectedWord = selectedCells
      .map((cell) => grid[cell.x][cell.y])
      .join("");

    // Check if the selected word is in the list (case-insensitive)
    const matchedWord = stableWords.find(
      (word) => word.toUpperCase() === selectedWord.toUpperCase()
    );

    if (matchedWord && !foundWords.includes(matchedWord)) {
      // Word found
      setFoundWords([...foundWords, matchedWord]);

      // Add all selected cells to foundWordCells
      setFoundWordCells((prev) => [...prev, ...selectedCells]);

      // setMessage(`لقيت الحل`);
    } else {
      // Invalid selection
      // setMessage("إختيار غير صحيح. حاول تاني");
    }

    // Reset selection
    setSelectionStart(null);
    setSelectedCells([]);
  };

  const finishGame = async () => {
    await GamesApi.addGamePoints({ points: 50 });
    navigate("/learn/score", {
      state: {
        isGame: true,
        totalQuizPoints: 50,
      },
    });
  };
  // Monitor when all words are found
  useEffect(() => {
    if (foundWords.length === stableWords.length && stableWords.length > 0) {
      finishGame();
    }
  }, [foundWords, stableWords.length]);

  // Mouse Event Handlers
  const handleMouseDown = (x, y) => {
    handleStartSelection(x, y);
  };

  const handleMouseEnter = (x, y) => {
    handleUpdateSelection(x, y);
  };

  const handleMouseUp = () => {
    handleEndSelection();
  };

  // Touch Event Handlers
  const handleTouchStart = (e) => {
    if (foundWords.length === stableWords.length) return; // All words found

    const touch = e.touches[0];
    const tableRect = tableRef.current.getBoundingClientRect();

    // Calculate which cell is being touched
    const cellWidth = tableRect.width / gridSize;
    const cellHeight = tableRect.height / gridSize;

    const x = Math.floor((touch.clientY - tableRect.top) / cellHeight);
    const y = Math.floor((touch.clientX - tableRect.left) / cellWidth);

    if (x >= 0 && x < gridSize && y >= 0 && y < gridSize) {
      handleStartSelection(x, y);
    }
  };

  const handleTouchMove = (e) => {
    if (!isSelecting || !selectionStart) return;

    const touch = e.touches[0];
    const tableRect = tableRef.current.getBoundingClientRect();

    // Calculate which cell is being touched
    const cellWidth = tableRect.width / gridSize;
    const cellHeight = tableRect.height / gridSize;

    const x = Math.floor((touch.clientY - tableRect.top) / cellHeight);
    const y = Math.floor((touch.clientX - tableRect.left) / cellWidth);

    if (x >= 0 && x < gridSize && y >= 0 && y < gridSize) {
      handleUpdateSelection(x, y);
    }
  };

  const handleTouchEnd = () => {
    handleEndSelection();
  };

  // Add global touch event listeners to handle touchend outside the grid
  useEffect(() => {
    const handleDocumentTouchEnd = () => {
      if (isSelecting) {
        handleEndSelection();
      }
    };
    document.addEventListener("touchend", handleDocumentTouchEnd);
    return () => {
      document.removeEventListener("touchend", handleDocumentTouchEnd);
    };
  }, [isSelecting, selectedCells]);

  const resetGame = () => {
    generateNewGrid();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 4,
        height: "100vh",
        userSelect: "none", // Prevent text selection during drag
      }}
    >
      <div
        style={{
          width: "100vw",
          paddingLeft: 40,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
        }}
      >
        <CardStack
          title="كلمة اليوم"
          description="إكسب نقط أكتر مع الغاز الكلمات"
          image={require("../puzzle-illustration-trans.png")}
        />
      </div>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
        }}
      ></Box>
      {/* Word search grid */}
      <Table
        ref={tableRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseUp={handleMouseUp}
        sx={{
          touchAction: "none", // Disable default touch actions
          // Responsive sizing
          backgroundColor: "#f9f9fc",
          width: {
            xs: "50vw", // 90% of viewport width on extra-small screens
            sm: "50vw", // 60% on small screens
            md: "30vw", // 40% on medium screens
            lg: "20vw", // 30% on large screens
          },
          // maxWidth: "500px", // Maximum width
          borderCollapse: "collapse", // Ensure fixed layout for consistent cell sizes
        }}
      >
        <TableBody>
          {grid.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                const isSelected = selectedCells.some(
                  (c) => c.x === rowIndex && c.y === cellIndex
                );
                const isFound = foundWordCells.some(
                  (c) => c.x === rowIndex && c.y === cellIndex
                );

                return (
                  <TableCell
                    key={cellIndex}
                    onMouseDown={() => handleMouseDown(rowIndex, cellIndex)}
                    onMouseEnter={() => handleMouseEnter(rowIndex, cellIndex)}
                    sx={{
                      width: "100%", // Make cells responsive
                      height: "0", // To maintain square cells
                      position: "relative", // For absolute positioning of content
                      border: "none", // Remove the cell border
                      cursor: "pointer",
                      backgroundColor: isFound
                        ? "#F1AA48" // Green for found words
                        : isSelected
                        ? "#90caf9" // Blue for current selection
                        : "transparent",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: !isFound ? "#e3f2fd" : undefined,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: {
                          xs: "1.2em",
                          sm: "1.5em",
                          md: "1.8em",
                        },
                        fontWeight: isFound ? "bold" : "normal",
                        // fontWeight: "bold",
                        pointerEvents: "none", // Prevent interfering with cell events
                      }}
                      className="tajawal"
                    >
                      {cell}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Feedback Message */}
      {message && (
        <Typography
          variant="subtitle1"
          color={
            message.includes("Congratulations") ? "success.main" : "primary"
          }
          sx={{ marginTop: 2 }}
        >
          {message}
        </Typography>
      )}

      {/* Word list */}
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          دوَّر على الكلامات دي
        </Typography>
        <Box
          component="ul"
          sx={{
            padding: 0,
            listStyleType: "none",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {words.map((word, index) => (
            <Box
              component="li"
              key={index}
              sx={{
                backgroundColor:
                  foundWords.includes(word.toUpperCase()) || isFinished
                    ? "#a5d6a7"
                    : "#e0e0e0",
                padding: "8px 12px",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight:
                    foundWords.includes(word.toUpperCase()) || isFinished
                      ? "bold"
                      : "normal",
                  textDecoration:
                    foundWords.includes(word.toUpperCase()) || isFinished
                      ? "line-through"
                      : "none",
                }}
              >
                {word}
              </Typography>
            </Box>
          ))}
        </Box>
        {isFinished ? (
          <div className="definition_base">
            <Typography variant="body1" className="tajawal">
              سعر الفايدة ببساطة هي النسبة اللي البنك بياخدها منك لو بتاخد قرض،
              أو بيديهالك لو إنت حاطط فلوسك عنده في حساب توفير. زي "إيجار"
              الفلوس اللي بتاخدها أو بتحوشها! 😊
            </Typography>
          </div>
        ) : null}
        <Button
          className="current-button"
          style={{ margin: "auto", width: "55vw", marginTop: "2.5vh" }}
          onClick={() => {
            navigate("/learn/summaries");
          }}
        >
          <Typography
            className="tajawal"
            variant="body1"
            fontWeight={"700"}
            color="white"
            style={{
              transform: "translateY(.5vh)",
            }}
          >
            رجوع للصفحة السابقة
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default WordSearch;
