import React, { useState, useMemo, useRef, useEffect } from "react";
import TinderCard from "react-tinder-card";
import "../tutorial/tutorial.styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";
import { EditorProvider, Mark, Node } from "@tiptap/react";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Code from "@tiptap/extension-code";
import StarterKit from "@tiptap/starter-kit";
import { SummariesApis } from "../../api";
import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import useHaptic from "../../hooks/useHaptic";

function Quiz2Page() {
  const TIMER_DURATION = 10000;
  const { summary_id } = useParams(); // Access the id parameter from the URL
  const location = useLocation();
  const item = location.state;
  const flashcardsStack = [...item.flashCards].reverse();
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState({}); // Store timer for each card
  const [isCanSwipe, setCanSwipe] = useState(true); // Track if swipe is allowed
  const countdownTimerRef = useRef(null);
  const navigate = useNavigate();
  const answersFromStorage = localStorage.getItem("answers");
  const finishedSummaries = localStorage.getItem("finishedSummaries");
  const isSummaryFinished = JSON.parse(finishedSummaries)?.includes(
    summary_id.toString()
  );
  const [isClickable, setIsClickable] = useState(!isSummaryFinished);
  const [currentIndex, setCurrentIndex] = useState(flashcardsStack.length - 1);

  //   useEffect(() => {
  //     resetClickable();
  //   }, [currentIndex]);

  const resetClickable = (val) => {
    console.log({ val });
    if (isSummaryFinished) {
      setIsClickable(false);
      return;
    }

    const cIndex = val !== null && val !== undefined ? val : currentIndex;

    const flag =
      answers[flashcardsStack[cIndex]._id] &&
      (answers[flashcardsStack[cIndex]._id].skipped ||
        answers[flashcardsStack[cIndex]._id].submitted);
    //       !!flashcardsStack[currentIndex].answers.find(
    //   (answer) =>
    //     answer._id === answers[flashcardsStack[currentIndex]._id].answerId ||
    //     answer._id === answers[flashcardsStack[currentIndex]._id]._id ||
    //     answers[flashcardsStack[currentIndex]._id].skipped
    // );
    console.log(cIndex, answers[flashcardsStack[cIndex]._id]);

    setIsClickable(!flag);
  };
  const [cardsStyle, setCardsStyle] = useState({});
  const initialize = () => {
    const arr = flashcardsStack.map((card) => {});
    const obk = arr.reduce((acc, curr, index) => {
      acc[index] = {};
      return acc;
    }, {});
    setCardsStyle(obk);
    const parsedAnswers = JSON.parse(answersFromStorage) ?? [];

    const summaryAnswers =
      parsedAnswers && Array.isArray(parsedAnswers) && parsedAnswers.length > 0
        ? parsedAnswers.reduce((acc, { answerId, cardId, summaryId }) => {
            if (!summaryId) {
              return acc;
            }
            // If the summaryId doesn't exist in the accumulator, create it with an empty object
            if (!acc[summaryId]) {
              acc[summaryId] = {};
            }

            // Assign the cardId as the key, and answerId as the value
            acc[summaryId][cardId] = { answerId };

            return acc;
          }, {})
        : parsedAnswers;

    // Access answers for the specific summaryId
    const thissummaryAnswers = summaryAnswers[summary_id];
    if (thissummaryAnswers) {
      const initAnswers = thissummaryAnswers;

      // Loop over each cardId in initAnswers
      Object.keys(initAnswers).map((cardId) => {
        const flashcard = flashcardsStack.find((card) => card._id === cardId);
        const answer = flashcard?.answers.find(
          (answer) => answer._id === initAnswers[cardId].answerId
        );
        // Update the answers with the found answer and set it as submitted
        if (answer) {
          initAnswers[cardId] = { ...answer, submitted: true };
        }

        // Set the updated answers to state
        setAnswers(initAnswers);
      });
    }

    updateProgressBarValue();
  };

  const updateProgressBarValue = () => {
    const progressBarVal =
      ((flashcardsStack.length - currentIndex - 1) / flashcardsStack.length) *
      100;

    setProgressBarValue(progressBarVal);
  };
  const [answers, setAnswers] = useState({});
  const [progressBarValue, setProgressBarValue] = useState({});
  useEffect(() => {
    initialize();
  }, [answersFromStorage, item]);

  const handleAnswerPick = (answer, cardId) => {
    if (isClickable) {
      const dict = { ...answers, [cardId]: { ...answer, submitted: false } };
      setAnswers(dict);
    }
  };

  const [refresh, setRefresh] = useState(0);

  const handleAnswerSubmit = (cardId) => {
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current); // Clear the timer
      countdownTimerRef.current = null; // Reset the reference
    }

    setTimer((prevTimers) => ({
      ...prevTimers,
      [cardId]: 0,
    }));
    setCanSwipe(true); // Re-enable swiping after timer ends

    let dict = answers;
    if (!dict[cardId]) {
      dict[cardId] = { skipped: true };
    }
    dict[cardId].submitted = true;
    setAnswers({ ...dict });
    setRefresh(refresh + 1);
    resetClickable();
    forceReRender();
  };

  const [lastDirection, setLastDirection] = useState();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(flashcardsStack.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
    updateProgressBarValue();
    console.log({ val });
    resetClickable(val);
  };

  const canGoBack = currentIndex < flashcardsStack.length - 1;
  const canGoForward = currentIndex > 0;

  const canSwipe =
    currentIndex >= 0 &&
    flashcardsStack[currentIndex].order !== flashcardsStack.length;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
  };

  const [dummyState, setDummyState] = useState(0);

  const forceReRender = () => {
    setDummyState((prev) => prev + 1);
  };

  const triggerVibration = useHaptic();

  const outOfFrame = (name, idx) => {
    const newStyles = cardsStyle;
    newStyles[idx] = { display: "none" };
    setCardsStyle(newStyles);
    // handle the case in which go back is pressed before card goes outOfFrame
    triggerVibration(100);
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (!canGoForward) return;
    if (canSwipe && currentIndex < flashcardsStack.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    const newStyles = cardsStyle;
    newStyles[newIndex] = {};
    setCardsStyle(newStyles);
    await childRefs[newIndex].current.restoreCard();
  };

  const handleNavigate = async () => {
    navigate("/learn/summaries");
  };

  const handleSummaryFinish = async () => {
    let totalPoints = 0;
    if (!isSummaryFinished) {
      setIsLoading(true);
      const { totalBonusPoints } = Object.values(answers).reduce(
        (prev, curr) => {
          prev.totalBonusPoints += curr.correct ? 1 : 0;
          return prev;
        },
        {
          totalBonusPoints: 0,
        }
      );
      totalPoints = totalBonusPoints;
      const mappedAnswers = Object.keys(answers).map((cardId) => {
        return {
          summaryId: summary_id,
          cardId: cardId,
          answerId: answers[cardId]._id,
        };
      });

      const payload = {
        answers: mappedAnswers,
        summaryId: summary_id,
        totalPoints: totalPoints,
      };

      await SummariesApis.finishSummary(payload);

      setIsLoading(false);
    }
    navigate("/learn/score", {
      state: {
        isFinished: isSummaryFinished,
        isTutorial: false,
        totalQuizPoints: totalPoints,
      },
    });
  };

  const startTimer = (cardId) => {
    if (answers[cardId] && Object.keys(answers[cardId])) {
      handleAnswerSubmit(cardId);
      return;
    }

    setCanSwipe(false); // Disable swiping initially
    // Set the initial timer value
    setTimer((prevTimers) => ({
      ...prevTimers,
      [cardId]: TIMER_DURATION,
    }));

    const int = currentIndex === flashcardsStack.length - 1 ? 50 : 100;
    countdownTimerRef.current = setInterval(() => {
      // Decrease the timer value by 1000ms every second
      setTimer((prevTimers) => ({
        ...prevTimers,
        [cardId]: prevTimers[cardId] - int,
      }));
    }, 100);

    // After the timer duration, reset the timer and allow swiping
    setTimeout(() => {
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
        setTimer((prevTimers) => ({
          ...prevTimers,
          [cardId]: 0,
        }));

        handleAnswerSubmit(cardId); // Automatically submit the answer if time runs out
        setCanSwipe(true); // Re-enable swiping after timer ends
      }
    }, TIMER_DURATION);
  };
  // Initialize the timer for each card when it comes into view
  useEffect(() => {
    if (
      flashcardsStack[currentIndex].type === "QUIZ" &&
      (!answers[flashcardsStack[currentIndex]._id] ||
        (answers[flashcardsStack[currentIndex]._id] &&
          !answers[flashcardsStack[currentIndex]._id].submitted))
    ) {
      startTimer(flashcardsStack[currentIndex]._id);
    }
  }, [currentIndex]);

  // Scroll to the top of the page when the component is first mounted
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <div className="cont">
      <div className="header">
        <LinearProgress
          dir="rtl"
          variant="determinate"
          value={
            ((flashcardsStack.length - currentIndex - 1) /
              flashcardsStack.length) *
            100
          }
          sx={{
            height: "4px", // Set the height to increase thickness
            marginRight: 2,
            borderRadius: 5,
            transform: "scale(-1, 1)",
            width: "100%",
            backgroundColor: "#fff",
            color: "red",
            "& .MuiLinearProgress-bar": {
              opacity: 1,
              backgroundColor: "#4CB04A !important",
            },
          }}
        />
        <FiX
          style={{ color: "#fff", marginLeft: "auto" }}
          size={"10vw"}
          onClick={handleNavigate}
        />
      </div>
      <div className="cardContainer">
        {flashcardsStack.map((character, index) => (
          <TinderCard
            ref={childRefs[index]}
            className={`swipe`}
            flickOnSwipe={true}
            swipeRequirementType="position"
            key={character._id}
            onSwipe={(dir) => swiped(dir, character._id, index)}
            onCardLeftScreen={() => {
              outOfFrame(character._id, index);
              forceReRender();
            }}
            sx={cardsStyle[index] || {}}
          >
            <div
              style={{
                ...cardsStyle[index],
                justifyContent: character.justifyContent || "center",
              }}
              className="card"
            >
              {character.type === "INFO" &&
              character.imagePlacement === "top" &&
              character.illustration ? (
                <img
                  src={character.illustration}
                  className="illustration"
                  style={{
                    width: `${
                      !Number(character.imageSize) ? "50" : character.imageSize
                    }vw`,
                  }}
                />
              ) : null}
              <div
                style={
                  character.type === "QUIZ"
                    ? {
                        borderRadius: 20,
                        backgroundColor: "rgba(240, 240, 240, 0.5)",
                        alignItems: "center",
                        marginVertical: 20,
                        width: "90%",
                        padding: 10,
                        justifyContent: "center",
                        textAlign: "right",
                        display: "flex",
                        fontWeight: "bolder",
                      }
                    : {
                        // minHeight: "60%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "90%",
                        direction: "rtl",
                      }
                }
              >
                {character.type === "QUIZ" ? (
                  <div
                    style={{
                      fontSize: "4vw",
                      fontWeight: "700",
                      color: "black",
                      padding: "1vw",
                    }}
                  >
                    {character.content}
                  </div>
                ) : (
                  <EditorProvider
                    extensions={[
                      Color.configure({
                        types: [TextStyle.name, ListItem.name],
                      }),
                      TextStyle.configure({
                        types: [ListItem.name],
                        HTMLAttributes: {
                          dir: "rtl",
                        },
                      }),
                      Code.configure({
                        HTMLAttributes: {
                          dir: "rtl",
                          class: "code-style",
                          className: "code-style",
                        },
                        keepMarks: true,
                        keepAttributes: false,
                      }),
                      StarterKit.configure({
                        bulletList: {
                          keepMarks: true,
                          HTMLAttributes: { dir: "rtl" },
                          keepAttributes: false,
                        },
                        orderedList: {
                          HTMLAttributes: { dir: "rtl" },
                          keepMarks: true,
                          keepAttributes: false,
                        },
                        codeBlock: {
                          HTMLAttributes: {
                            class: "pre-style",
                            className: "pre-style",
                            dir: "rtl",
                          },
                          keepMarks: true,
                          keepAttributes: false,
                        },
                        code: {
                          keepMarks: true,
                          HTMLAttributes: {
                            class: "code-style",
                            dir: "rtl",
                          },
                          keepAttributes: false,
                        },
                      }),
                    ]}
                    content={character.content
                      .replace(
                        /<pre>/g,
                        '<pre className="pre-style" class="pre-style">'
                      )
                      .replace(/<\/pre>/g, "</pre>")
                      .replace(
                        /<code>/g,
                        '<code className="code-style" class="code-style">'
                      )
                      .replace(/<\/code>/g, "</code>")}
                  ></EditorProvider>
                )}
              </div>

              {character.type === "QUIZ" && (
                <div
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                    width: "90%",
                  }}
                >
                  {character.type === "QUIZ" &&
                    character.answers.length > 0 &&
                    character.answers.map((answer, index) => (
                      <button
                        key={index}
                        disabled={
                          answers[character._id] &&
                          answers[character._id].submitted
                        }
                        onTouchEnd={() =>
                          handleAnswerPick(answer, character._id)
                        }
                        onClick={(e) => {
                          handleAnswerPick(answer, character._id);
                        }}
                        className={`${
                          answers[character._id] &&
                          answers[character._id]._id === answer._id &&
                          answers[character._id].submitted &&
                          !answers[character._id].correct
                            ? "incorrect-answer"
                            : answers[character._id] &&
                              (answers[character._id].submitted ||
                                answers[character._id].skipped) &&
                              answer.correct
                            ? "correct-answer"
                            : answers[character._id] &&
                              answers[character._id]._id === answer._id &&
                              !answers[character._id].submitted
                            ? "picked-answer"
                            : "unpicked-answer"
                        } answer`}
                        style={{
                          zIndex: 99,
                          backgroundColor: "rgba(240, 240, 240, 0.5)",
                          width: "90%",
                          borderRadius: 10,
                          margin: 15,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            width: "7%",
                            aspectRatio: 1,
                            borderRadius: 100,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "grey",
                          }}
                          className={`${
                            answers[character._id] &&
                            answers[character._id]._id === answer._id &&
                            answers[character._id].submitted &&
                            !answers[character._id].correct
                              ? "incorrect_indicator_icon"
                              : answers[character._id] &&
                                (answers[character._id].submitted ||
                                  answers[character._id].skipped) &&
                                answer.correct
                              ? "correct_indicator_icon"
                              : answers[character._id] &&
                                answers[character._id]._id === answer._id &&
                                !answers[character._id].submitted
                              ? "picked_indicatior_icon"
                              : "unpicked-answer "
                          } indicator`}
                        >
                          {answers[character._id] &&
                          answers[character._id]._id === answer._id &&
                          answers[character._id].submitted &&
                          !answers[character._id].correct ? (
                            <FaXmark
                              size="4.1vw"
                              color="#fff"
                              style={{ fontWeight: "bolder" }}
                            />
                          ) : answers[character._id] &&
                            (answers[character._id].submitted ||
                              answers[character._id].skipped) &&
                            answer.correct ? (
                            <FaCheck
                              size="4vw"
                              fontWeight={"700"}
                              color="#fff"
                            />
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: "80%",
                            color: "black",
                            textAlign: "right",
                            fontSize: "3.5vw",
                            marginHorizontal: 10,
                            padding: "1vw",
                          }}
                        >
                          {answer.content}
                        </div>
                      </button>
                    ))}
                </div>
              )}

              {character.type === "QUIZ" &&
                answers[character._id] &&
                answers[character._id].submitted &&
                !answers[character._id].skipped &&
                answers[character._id].correct && (
                  <div className="bonusText">!كسبت نقطة إضافية</div>
                )}

              <div className="buttonRow">
                {character.type === "QUIZ" &&
                answers[character._id] &&
                !answers[character._id].submitted ? (
                  <>
                    <button
                      onClick={() => handleAnswerSubmit(character._id)}
                      onTouchEnd={() => handleAnswerSubmit(character._id)}
                      className="popout current-button"
                      style={{
                        margin: "auto",
                        marginBottom: 10,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "4vw",
                        }}
                      >
                        جاوب
                      </div>
                    </button>
                  </>
                ) : null}
              </div>
              {character.type === "INFO" &&
              character.imagePlacement === "bottom" &&
              character.illustration ? (
                <div>
                  <img
                    src={character.illustration}
                    className="illustration"
                    style={{
                      width: `${
                        !Number(character.imageSize)
                          ? "50"
                          : character.imageSize
                      }vw`,
                    }}
                  />
                </div>
              ) : null}
              {index === 0 ? (
                <>
                  <button
                    onClick={() => handleSummaryFinish()}
                    onTouchEnd={() => handleSummaryFinish()}
                    className="popout current-button"
                    style={{
                      margin: "auto",
                      marginBottom: 10,
                      // backgroundColor: "#305F72",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 10,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "4vw",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress size={14} sx={{ color: "#fff" }} />
                      ) : isSummaryFinished ? (
                        "انهاء الدرس"
                      ) : (
                        "انهاء الدرس"
                      )}
                    </div>
                  </button>
                </>
              ) : null}

              {character.type === "QUIZ" &&
              (!answers[character._id] ||
                (answers[character._id] &&
                  !answers[character._id].submitted)) ? (
                <div
                  style={{
                    width: "90%",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    dir="rtl"
                    value={
                      (-(
                        TIMER_DURATION -
                        (TIMER_DURATION + timer[character._id])
                      ) /
                        TIMER_DURATION) *
                      100
                    }
                    sx={{
                      height: "4px", // Set the height to increase thickness
                      margin: 2,
                      borderRadius: 10,
                      // backgroundColor: "#305F72",
                      backgroundColor: "#fff",
                      color: "red",
                      border: "1px solid #305F72",
                      "& .MuiLinearProgress-bar": {
                        opacity: 1,
                        backgroundColor: "#4CB04A !important",
                      },
                    }}
                  />
                  <div className="timer_countdown_base">
                    <Typography
                      className="tajawal rtl"
                      sx={{
                        direction: "rtl",
                        fontSize: "4vw",
                        fontWeight: "500",
                        color: "#305F72",
                      }}
                    >
                      {`${
                        isCanSwipe ? 0 : Math.ceil(timer[character._id] / 1000)
                      } ثانية متبقية`}
                    </Typography>
                    <img
                      src={require("./alarm_clock.gif")}
                      className="alarm_icon"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </TinderCard>
        ))}
      </div>
      <div className="buttons">
        <FiChevronLeft
          onClick={() => {
            if (
              flashcardsStack[currentIndex].type === "INFO" ||
              (answers[flashcardsStack[currentIndex]._id] &&
                answers[flashcardsStack[currentIndex]._id].submitted)
            ) {
              goBack();
            }
          }}
          size={"13vw"}
          style={{
            color:
              !canGoBack ||
              !(
                flashcardsStack[currentIndex].type === "INFO" ||
                (answers[flashcardsStack[currentIndex]._id] &&
                  answers[flashcardsStack[currentIndex]._id].submitted)
              )
                ? "grey"
                : "#fff",
          }}
        />
        <FiChevronRight
          onClick={() => {
            if (
              flashcardsStack[currentIndex].type === "INFO" ||
              (answers[flashcardsStack[currentIndex]._id] &&
                answers[flashcardsStack[currentIndex]._id].submitted)
            ) {
              swipe("right");
            }
          }}
          size={"13vw"}
          style={{
            color:
              !canGoForward ||
              !(
                flashcardsStack[currentIndex].type === "INFO" ||
                (answers[flashcardsStack[currentIndex]._id] &&
                  answers[flashcardsStack[currentIndex]._id].submitted)
              )
                ? "grey"
                : "#fff",
          }}
        />
      </div>
    </div>
  );
}

export default Quiz2Page;
