import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  LoginPage,
  TutorialPage,
  SummaryPage,
  ScorePage,
  ScorePageDesktop,
  GamesPage,
  WotdPage,
} from "./pages";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TutorialPageDesktop from "./pages/tutorial/tutorial.page-desktop";
import SummaryPageDesktop from "./pages/summary/summary.page.desktop";
import QuizPage from "./pages/quiz/quiz.page";
import QuizPageDesktop from "./pages/quiz/quiz.page-desktop";
import UserPage from "./pages/user/user.page";
import UserPageDesktop from "./pages/user/user.page.desktop";
import WordSearch from "./pages/games/search.page";
import ChangeEmail from "./pages/settings/email.page";
import ChangePassword from "./pages/settings/password.page";
import ChangeMobileNumber from "./pages/settings/mobile.email.page";
import RewardScreen from "./pages/rewards/reward.page";
import HassalaPage from "./pages/hassala/hassala.page";
import InfoPage from "./pages/landing";
import { Button, Typography } from "@mui/material";
import Quiz2Page from "./pages/quiz/quiz-2.page";

function App() {
  const isLoggedIn = localStorage.getItem("token");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const location = window.location;
  const [showReminder, setShowReminder] = useState(false);
  useEffect(() => {
    if (
      !isLoggedIn &&
      location.pathname !== "/login" &&
      location.pathname !== "/info" &&
      !location.pathname.includes("/forgot-password")
    ) {
      window.location.replace("/login");
    }

    if (["/login", "/", "/info", ""].includes(location.pathname)) {
      setShowReminder(true);
    } else {
      setShowReminder(false);
    }
  }, [isLoggedIn, location.pathname]);
  useEffect(() => {}, [isMobile]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // This will hide the address bar in mobile browsers
    if (window.innerHeight < window.outerHeight) {
      window.scrollTo(0, 1);
    }
  }, []);

  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default install prompt
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); // Show the custom install prompt
    };

    const handleAppInstalled = () => {
      console.log("PWA was installed!");
      setIsInstallable(false); // Hide install prompt if app is installed
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the native install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the installation");
        } else {
          console.log("User dismissed the installation prompt");
        }
        setDeferredPrompt(null); // Reset the deferred prompt after user interaction
        setIsInstallable(false); // Hide the custom prompt
      });
    }
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/forgot-password/:new_password_email"
            element={<LoginPage forgotPasswordFlag={true} />}
          />

          {/* <Route path="/manga" element={<ScorePage />} /> */}

          <Route
            path="/learn/tutorials"
            element={
              isMobile ? (
                <HassalaPage type="tutorials" />
              ) : (
                <HassalaPage type="tutorials" />
              )
            }
          />
          <Route
            path="/learn/summaries"
            element={
              isMobile ? (
                <HassalaPage type="summaries" />
              ) : (
                <HassalaPage type="summaries" />
              )
            }
          />
          <Route
            path="/learn/:tutorial_id"
            element={isMobile ? <TutorialPage /> : <TutorialPageDesktop />}
          />
          <Route
            path="/learn/summary/:summary_id"
            element={isMobile ? <SummaryPage /> : <SummaryPageDesktop />}
          />
          <Route
            path="/learn/summary/:summary_id/quiz"
            element={isMobile ? <Quiz2Page /> : <QuizPageDesktop />}
          />
          <Route
            path="/learn/score"
            element={isMobile ? <ScorePage /> : <ScorePageDesktop />}
          />
          <Route
            path="/user"
            element={isMobile ? <UserPage /> : <UserPageDesktop />}
          />
          <Route
            path="/activity"
            element={isMobile ? <GamesPage /> : <GamesPage />}
          />
          <Route
            path="/activity/wotd"
            element={isMobile ? <WotdPage /> : <WotdPage />}
          />
          <Route
            path="/activity/search"
            element={isMobile ? <WordSearch /> : <WordSearch />}
          />

          <Route
            path="/settings/email"
            element={isMobile ? <ChangeEmail /> : <ChangeEmail />}
          />
          <Route
            path="/settings/password"
            element={isMobile ? <ChangePassword /> : <ChangePassword />}
          />
          <Route
            path="/settings/mobile"
            element={isMobile ? <ChangeMobileNumber /> : <ChangeMobileNumber />}
          />
          <Route
            path="/rewards"
            element={
              isMobile ? (
                <HassalaPage type="rewards" />
              ) : (
                <HassalaPage type="rewards" />
              )
            }
          />

          <Route path="/dotted" element={<HassalaPage type="dotted" />} />
          <Route path="/info" element={<InfoPage />} />
        </Routes>
      </Router>
      {/* {showReminder ? (
        <div
          className="reminder-base"
          style={{
            position: "fixed",
            bottom: 0,
            width: "95%",
          }}
        >
          <Typography
            variant="body1"
            className="marhey"
            style={{ textAlign: "right" }}
          >
            ثبت حصالة على شاشة الرئيسية؟
          </Typography>
          <Button
            variant="contained"
            className="reminder-btn marhey"
            // onClick={handleInstallClick}
          >
            <Typography variant="body2" className="marhey">
              ثبت
            </Typography>
          </Button>
        </div>
      ) : null} */}
    </>
  );
}

export default App;
