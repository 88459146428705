import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import "./styles.css";

// Card component
const CustomCard = ({
  handleSubmit,
  payload,
  setPayload,
  isLoading,
  zIndex,
  transform,
  boxShadow,
}) => {
  return (
    <Grid
      item
      xs={10}
      sm={6}
      md={5}
      style={{
        transform: "translateY(-20%)",
        position: "absolute",
        zIndex: zIndex,
        transform: transform,
        boxShadow: boxShadow,
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
        borderRadius: 10,
        // height: "120px",
        width: zIndex === 3 ? "90%" : zIndex === 2 ? "85%" : "80%",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          className="marhey"
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
        >
          حصالة
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          gutterBottom
          className="tajawal"
        >
          غير كلمة السر
        </Typography>
        <Box type="submit" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            label="كلمة المرور"
            variant="outlined"
            margin="normal"
            dir="rtl"
            inputProps={{ style: { textTransform: "none" } }}
            required
            type="password"
            fullWidth
            value={payload.newPassword}
            onChange={(e) => {
              setPayload({ ...payload, newPassword: e.target.value });
            }}
          />
          <TextField
            label="تأكيد كلمة المرور"
            variant="outlined"
            margin="normal"
            dir="rtl"
            inputProps={{ style: { textTransform: "none" } }}
            required
            type="password"
            fullWidth
            value={payload.confirmPassword}
            onChange={(e) => {
              setPayload({ ...payload, confirmPassword: e.target.value });
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              mt: 3,
            }}
          >
            <Button
              type="submit"
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              className="current-button"
              color="primary"
              disabled={isLoading}
              sx={{ height: "45px" }}
            >
              <Typography style={{ fontSize: "4vw" }} className="marhey">
                {isLoading ? "" : "غير كلمة السر"}
              </Typography>
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const NewPasswordStack = ({
  handleSubmit,
  payload,
  setPayload,
  isLoading,
}) => {
  return (
    <div
      className="login-stack"
      style={{ position: "relative", height: "100vh" }}
    >
      {/* Stacked login form cards */}
      <CustomCard
        handleSubmit={handleSubmit}
        payload={payload}
        setPayload={setPayload}
        isLoading={isLoading}
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.3)" // Less shadow for the second card
        zIndex={3}
        transform="translateY(10px)"
      />
      <CustomCard
        handleSubmit={handleSubmit}
        payload={payload}
        setPayload={setPayload}
        isLoading={isLoading}
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.2)" // Less shadow for the second card
        zIndex={2}
        transform="translate(5px, 15px)"
      />
      <CustomCard
        handleSubmit={handleSubmit}
        payload={payload}
        setPayload={setPayload}
        isLoading={isLoading}
        zIndex={1}
        transform="translate(10px, 20px)"
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.1)"
      />
    </div>
  );
};

export default NewPasswordStack;
