import React, { useEffect, useState } from "react";
import "./topNavbar.styles.css";
import Lottie from "react-lottie";
import Coin from "./coins.json";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

const TopNavigationBarDesktop = ({ addedPoints = 0 }) => {
  const navigate = useNavigate();
  const totalPoints = localStorage.getItem("totalPoints");
  const [newTotalPoints, setNewTotalPoints] = useState(totalPoints);

  const updateTotalPoints = () => {
    const newPoints = +newTotalPoints + +addedPoints;
    setNewTotalPoints(newPoints);
  };

  useEffect(() => {
    if (addedPoints !== 0) {
      updateTotalPoints();
    }
  }, [addedPoints]);
  return (
    <nav className="top-nav top-nav-box-shadow">
      <div className="lma2-dt">
        <div className="lma1">
          <Lottie
            style={{
              width: "2.25vw",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Coin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"2.25vw"}
            width={"2.25vw"}
            isStopped={false}
            isPaused={false}
          />
        </div>
        <CountUp
          delay={1}
          duration={1.5}
          style={{
            fontSize: "1.5vw",
            fontWeight: "700",
            color: "#305F72",
            transform: [{ translateX: -128 }],
          }}
          start={totalPoints - addedPoints}
          end={totalPoints}
        />
      </div>
      <button
        onClick={() => {
          localStorage.clear();
          navigate("/");
        }}
      >
        <FiLogOut size={30} />
      </button>
    </nav>
  );
};

export default TopNavigationBarDesktop;
