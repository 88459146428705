import axios from "axios";
import { baseUrl } from "./api";

export default class LoginApis {
  static login = async ({ password, mobile }) => {
    return axios
      .post(`${baseUrl}/users/login`, {
        password,
        mobile,
      })
      .then((res) => {
        localStorage.setItem(
          "answers",
          JSON.stringify(res.data.user.answers ?? [])
        );
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem(
          "totalPoints",
          JSON.stringify(res.data.user.totalPoints)
        );
        localStorage.setItem(
          "finishedTutorials",
          JSON.stringify(
            res.data.user.tutorials
              .filter((t) => t.isFinished)
              .map((t) => t.tutorial)
          )
        );
        localStorage.setItem(
          "finishedSummaries",
          JSON.stringify(
            res.data.user.summaries
              .filter((t) => t.isFinished)
              .map((t) => t.summary)
          )
        );
        return res;
      });
  };

  static updatePassword = async ({ password, email }) => {
    return axios.post(`${baseUrl}/users/password/forgot/update`, {
      password,
      email,
    });
  };

  static forgotPassword = async ({ email }) => {
    return axios.post(`${baseUrl}/users/password/forgot`, { email });
  };

  static registerUser = async (payload) => {
    return axios.post(`${baseUrl}/users/create`, payload).catch((err) => {
      throw err;
    });
  };

  static verifyActivationCode = async (payload) => {
    return axios
      .post(`${baseUrl}/users/activation/verify`, payload)
      .catch((err) => {
        throw err;
      });
  };
}
