import React from "react";
import "./user.styles.css"; // Styles for StreakTracker
import image from "./calendar.png";
import { Typography } from "@mui/material";
import tickImage from "./tick.png";
import Coin from "../../pages/score/coin.json";
import Lottie from "react-lottie";
import Coins from "../../components/shared/topNavBar/coins.json";
import { zIndex } from "material-ui/styles";

const StreakTracker = () => {
  const streakDays = [
    { day: 1, active: true, milestone: false, milestoneBonus: 0 },
    { day: 2, active: false, milestone: false, milestoneBonus: 0 },
    { day: 3, active: false, milestone: true, milestoneBonus: 20 },
    { day: 4, active: false, milestone: false, milestoneBonus: 0 },
    { day: 5, active: false, milestone: true, milestoneBonus: 50 },
    { day: 6, active: false, milestone: false, milestoneBonus: 0 },
    { day: 7, active: false, milestone: true, milestoneBonus: 100 },
  ]; // Array for streak days

  return (
    <div className="streak-tracker">
      <div>
        <h3 className="streak-title-no-shadow marhey">خط الفوز</h3>
        <h5 className="streak-subtitle tajawal">نقط الاسبوع</h5>
      </div>
      <img src={image} alt="Calendar" className="streak-icon" />
      <div className="streak-info">
        <Typography
          variant="body2"
          style={{ fontSize: "12px" }}
          className="tajawal"
        >
          أيام
        </Typography>
        <Typography
          variant="body1"
          className="days-text"
          style={{ fontSize: "14px" }}
          fontWeight="700"
        >
          {streakDays.filter((day) => day.active).length}
        </Typography>
      </div>
      <div className="streak-line"></div>
      <div className="days-container">
        {streakDays.map((day, index) => (
          <div className="day-coin">
            <div
              key={day.day}
              className={`day-item ${day.active ? "dActive" : null}`}
            >
              {day.active ? (
                <img src={tickImage} alt="Tick" className="tick_icon" />
              ) : (
                <>
                  <Typography variant="body2" className="day-label tajawal">
                    يوم
                  </Typography>
                  <Typography
                    variant="body1"
                    className="day-number"
                    fontWeight="700"
                  >
                    {day.day}
                  </Typography>
                </>
              )}
            </div>
            {day.milestone && (
              <div className="coin-text-base">
                <Lottie
                  // className="streak-coin"
                  key={day.day}
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: Coins,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={"3vh"}
                  width={"3vh"}
                  isStopped={true}
                  isPaused={true}
                />
                <Typography variant="body1" className="coint-text tajawal">
                  {day.milestoneBonus}
                </Typography>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="streak-line-milestones"></div>
      <div className="starting-point" />
      <p className="streak-message tajawal">متفوقش محتوى جديد وإكسب أكثر</p>
    </div>
  );
};

export default StreakTracker;
