import React from "react";
import "./score.styles.css";
import TopNavigationBarDesktop from "../../components/shared/topNavBar/topNavBar.component-desktop";
import TutorialScorePage from "./tutorial-score.page";
import { useLocation, useNavigate } from "react-router-dom";
import SummaryScorePage from "./summary-score.page";
import Confetti from "./confetti.json";
import Coin from "./coin.json";
import Lottie from "react-lottie";

function ScorePageDesktop() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isFinished,
    isTutorial,
    tutorialQuizPoints,
    tutorialBasePoints,
    totalQuizPoints,
  } = location.state;
  setTimeout(() => {
    navigate("/learn/tutorials");
  }, 4000);
  return (
    <div className="score-root">
      <TopNavigationBarDesktop
        addedPoints={
          isTutorial && !isFinished
            ? tutorialBasePoints + tutorialQuizPoints
            : totalQuizPoints
        }
      />
      <Lottie
        style={{
          position: "absolute",
          top: 0,
          width: "100vw",
        }}
        options={{
          loop: false,
          autoplay: true,
          animationData: Confetti,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"100vh"}
        width={"100vw"}
        isStopped={false}
        isPaused={false}
      />
      <div className="score-dt">
        {isTutorial ? (
          <TutorialScorePage
            isFinished={isFinished}
            totalPoints={tutorialBasePoints + tutorialQuizPoints}
            tutorialBasePoints={tutorialBasePoints}
            tutorialQuizPoints={tutorialQuizPoints}
          />
        ) : (
          <SummaryScorePage
            isFinished={isFinished}
            totalPoints={totalQuizPoints}
          />
        )}
      </div>
      {!isFinished ? (
        <div className="coin-base">
          <Lottie
            style={{
              position: "absolute",
              left: "50%",
              animation: `moveToTopLeft 2s forwards`,
              animationDelay: "0.5s",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Coin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"5vh"}
            width={"5vh"}
            isStopped={false}
            isPaused={false}
          />
          <Lottie
            style={{
              position: "absolute",
              left: "50%",
              animation: `moveToTopLeft 2s forwards`,
              animationDelay: "0.75s",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Coin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"5vh"}
            width={"5vh"}
            isStopped={false}
            isPaused={false}
          />
          <Lottie
            style={{
              position: "absolute",
              left: "50%",
              animation: `moveToTopLeft 2s forwards`,
              animationDelay: "1s",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Coin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"5vh"}
            width={"5vh"}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ScorePageDesktop;
