import React from "react";
import "./user.styles.css"; // Styles for MedalsCard
import { Typography } from "@mui/material";
import Lottie from "react-lottie";
import Coin from "../../components/shared/topNavBar/coins.json";

const PointsCard = ({ points }) => {
  return (
    <div className="points-card">
      <div className="card-header">
        <div className="lmao">
          <Lottie
            style={{
              width: "13.5vw",
              margin: "none",
            }}
            options={{
              loop: false,
              autoplay: false,
              animationData: Coin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"13.5vw"}
            width={"13.5vw"}
            isStopped={true}
            isPaused={true}
          />{" "}
          <div className="points-value">محمل النقط</div>
        </div>
        <div>
          <Typography
            variant="h5"
            // style={{ textAlign: "right" }}
            dir="rtl"
            color="text.secondary"
            fontWeight={"600"}
            className="marhey"
          >
            {points}
          </Typography>
        </div>
        {/* <h3 className="points-title">0</h3> */}
      </div>
      <ul className="points-list">
        <li>
          <span className="item-value">{points - 65}</span>
          <span className="item-label tajawal">دروس</span>
        </li>
        <li>
          <span className="item-value">25</span>
          <span className="item-label tajawal">ملخصات</span>
        </li>
        <li>
          <span className="item-value">10</span>
          <span className="item-label tajawal">خط الفوز</span>
        </li>
        <li>
          <span className="item-value">30</span>
          <span className="item-label tajawal">ألعاب</span>
        </li>
      </ul>
    </div>
  );
};

export default PointsCard;
