import React, { useMemo } from "react";
import "./tutorials.styles.css";
import ExpandableCircle from "../../components/tutorials/expandable-circle.component";
import CardStack from "../../components/cardStack/cardStack";

const TutorialsPage = ({ listing, userProgress }) => {
  // Memoizing finishedTutorialsDict to avoid unnecessary recalculations
  const finishedTutorialsDict = useMemo(() => {
    return userProgress?.finishedTutorials.reduce((prev, curr) => {
      if (!prev[curr.tutorial.topic]) {
        prev[curr.tutorial.topic] = {
          number: 0,
          tutorials: [],
        };
      }

      prev[curr.tutorial.topic].number += 1;
      prev[curr.tutorial.topic].tutorials.push(curr.tutorial._id);
      return prev;
    }, {});
  }, [userProgress?.finishedTutorials]); // Only recalculate when finishedTutorials change

  return (
    <div className="tutorials-container">
      <CardStack
        title="الأكاديمية"
        description="كمل الدروس والمواضيع علشان تكسب نقط في رحلتك التعليمية"
        image={require("../bank-illustration-transparent.png")}
      />

      <div style={{ height: "5vh" }} />
      {Object.keys(listing).length > 0 &&
        Array.isArray(userProgress?.unlockedTutorials) &&
        Array.isArray(userProgress?.finishedTutorials) &&
        Object.keys(listing).map((topicName, index) => (
          <ExpandableCircle
            key={index}
            image={listing[topicName].logo}
            title={topicName}
            description={listing[topicName].description}
            tutorials={listing[topicName].tutorials}
            finishedTutorialsDict={finishedTutorialsDict[topicName]}
            unlockedTutorials={userProgress?.unlockedTutorials.map(
              (t) => t._id
            )}
            finishedTutorials={userProgress?.finishedTutorials.map(
              (t) => t.tutorial
            )}
          />
        ))}
      <div style={{ height: "10vh" }} />
    </div>
  );
};

export default TutorialsPage;
