import React, { useEffect } from "react";
import "./user.styles.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigationBar, TopNavigationBar } from "../../components";
import TopNavigationBarDesktop from "../../components/shared/topNavBar/topNavBar.component-desktop";

const UserPageDesktop = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const summary = location.state;

  useEffect(() => {}, []);

  const handleSummariesNavigation = (link) => {
    navigate(`/learn/summary/${summary._id}/quiz`, {
      state: { flashCards: summary.quiz.questions },
    });
  };

  return (
    <div>
      <TopNavigationBarDesktop />
      <div className="current-summary-dt">
        <Card style={{ borderRadius: 10 }}>
          <CardMedia component="img" height={"401px"} image={summary.banner} />
          <CardContent>
            {summary.sections.map((section) => (
              <div>
                <div
                  className="tajawal"
                  style={{
                    textAlign: "right",
                    fontSize: "1vw",
                    fontWeight: "700",
                    borderBottom: "0.5px solid lightgrey",
                  }}
                  dir="rtl"
                >
                  <h3>{section.heading}</h3>
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                  dangerouslySetInnerHTML={{ __html: section.content }}
                  dir="rtl"
                />
              </div>
            ))}
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "3vh" }}
            >
              <Button
                className="current-button"
                style={{ margin: "auto", width: "80%" }}
                onClick={() => handleSummariesNavigation()}
              >
                <Typography
                  className="tajawal"
                  variant="body1"
                  fontWeight={"700"}
                  color="white"
                >
                  اذهب إلى الاختبار
                </Typography>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <BottomNavigationBar />
    </div>
  );
};

export default UserPageDesktop;
