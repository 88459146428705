import React from "react";
import "./styles.css";
const About = ({ aboutRef, locale = "ar" }) => {
  const image = require("./about.png");

  return (
    <div ref={aboutRef} className="about">
      <div
        style={
          locale === "ar"
            ? { direction: "rtl" }
            : {
                direction: "ltr",
                textAlign: "left",
                marginRight: "auto",
              }
        }
        className={locale === "ar" ? "aboutText dir-rtl" : "aboutText"}
      >
        {locale === "en" ? (
          <>
            <h2 style={{ fontSize: "1.5em" }}>Your Partner in Financial</h2>
            <h2 style={{ fontSize: "1.5em" }}>Education</h2>
          </>
        ) : (
          <h2 style={{ fontSize: "1.5em" }}>شريككم في التثقيف المالي</h2>
        )}
        {locale === "en" ? (
          <p
            style={
              locale === "ar"
                ? { direction: "rtl" }
                : {
                    direction: "ltr",
                    textAlign: "left",
                    marginRight: "auto",
                  }
            }
          >
            We are on a mission to pave the road to financial well-being for
            millions of young people. The Hassala application was built to guide
            your clients on this journey. It delivers curated content in a
            bite-sized format and makes the learning experience fun with
            gamification and rewards.
          </p>
        ) : (
          <p>
            حن في مهمة لتمهيد الطريق لملايين من الشباب نحو النجاح المالي و حياة
            الرحلة، حيث يقدم محتوى مختاراً بعناية و بأسلوب مبسط، ويجعل التعلم
            مسلّياً ومحفّزاً من خلال التحديات التفاعلية والجوائز. أكثر ازدهاراً.
            تم تصميم تطبيق حصالة لمرافقة عملائكم في هذه{" "}
          </p>
        )}
      </div>
      <div className="aboutImage">
        <img src={image} className="image" alt="About image" />
      </div>
    </div>
  );
};

export default About;
