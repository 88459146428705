import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Coin from "../../components/shared/topNavBar/coins.json";
import { BottomNavigationBar, TopNavigationBar } from "../../components";

const Banner = styled(Box)(({ imageUrl }) => ({
  width: "100%",
  height: "25vh",
  backgroundImage: `url(${imageUrl})`, // Dynamically apply the background image
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const CardBox = styled(Box)({
  backgroundColor: "#ebedf5",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  marginTop: "-1px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Less shadow for the second card

  zIndex: 10,
});

const InfoSection = styled(Box)({
  marginTop: "20px",
  padding: "0 20px",
});

const RedeemButton = styled(Button)({
  marginTop: "20px",
  // backgroundColor: "#305F72",
  color: "white",
  // "&:hover": {
  // backgroundColor: "#1e3d50",
  // },
});

function RewardScreen() {
  const location = useLocation();
  const { reward } = location.state;

  return (
    <>
      <TopNavigationBar />
      <Box dir="rtl">
        {/* Banner Image */}
        <Banner imageUrl={reward.image} />

        {/* Main Content */}
        <Box display="flex" justifyContent="center" marginTop="-50px">
          <Box width="90%" maxWidth="600px">
            {/* Card Box with info */}
            <CardBox>
              <Typography className="tajawal" variant="h6" color="textPrimary">
                {reward.organization}
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color="textPrimary"
                marginTop="10px"
                className="marhey"
              >
                {reward.name}
              </Typography>

              <div className="reward-chips-no-translate">
                <span className="reward-chip quantity">
                  متبقي {reward.quantity}
                </span>
                <span className="reward-chip price">
                  {reward.price}
                  <Lottie
                    style={{
                      width: "7vw",
                      margin: "none",
                    }}
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: Coin,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={"7vw"}
                    width={"7vw"}
                    isStopped={true}
                    isPaused={true}
                  />
                </span>
              </div>

              {/* Description */}
              <InfoSection>
                <Typography
                  className="tajawal"
                  variant="body1"
                  color="textSecondary"
                >
                  {reward.description}
                </Typography>
              </InfoSection>

              {/* Redeem Button */}
            </CardBox>
              <RedeemButton
                variant="contained"
                fullWidth
                className="current-button"
              >
                إستبدل نقطك
              </RedeemButton>
          </Box>
        </Box>
      </Box>
      <BottomNavigationBar />
    </>
  );
}

export default RewardScreen;
