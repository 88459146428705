import React from "react";
import { Typography } from "@mui/material";

const PrivacyPolicyComponent = () => {
  return (
    <div className="tajawal">
      <Typography className="tajawal modal-p">
        <p className="tajawal modal-p">
          نقوم بجمع وإدارة بيانات المستخدم وفقًا لسياسة الخصوصية التالية:
        </p>

        <h3 className="tajawal modal-headline">البيانات التي نجمعها</h3>
        <p className="tajawal modal-p">
          نقوم بجمع البيانات التي تقوم بتقديمها مباشرة لنا. على سبيل المثال،
          نجمع البيانات عندما تقوم بإنشاء حساب أو الاشتراك في أي من خدماتنا
          التفاعلية على سبيل المثال لا الحصر: ملء نموذج، طلب الدعم، أو التواصل
          معنا. تشمل أنواع البيانات التي قد نجمعها اسمك وعنوان بريدك الإلكتروني
          وغيرها من بيانات الإتصال أو التي تختار تقديمها.
        </p>

        <p className="tajawal modal-p">
          قد نقوم بجمع بيانات مجهولة المصدر من كل زائر لموقعنا لمراقبة حركة
          الزوار وإجراء التعديلات و تصحيح الأخطاء. على سبيل المثال، نقوم بجمع
          بيانات مثل طلبات الشبكة والبيانات الخاصة بهذه الطلبات وعنوان بروتوكول
          الإنترنت ونوع المتصفح ولغته والطابع الزمني للطلب.
        </p>

        <h3 className="tajawal modal-headline">استخدام البيانات</h3>
        <p className="tajawal modal-p">
          نحن نستخدم بياناتك الشخصية فقط لتزويدك بخدمات حصالة أو للتواصل معك
          بشأن الخدمات التي نقدمها. الشخصية التي قدمتها لنا دون موافقتك، إلا في
          حال:
        </p>
        <ul className="tajawal modal-p">
          <li>القيام بذلك مناسب لتنفيذ طلبك.</li>
          <li>إنها ضرورية لغرض قانوني.</li>
          <li>
            هناك حاجة لاكتشاف أو منع أو التعامل مع الاحتيال أو لدواعي الأمان أو
            المشكلات الفنية.
          </li>
        </ul>

        <h3 className="tajawal modal-headline">مشاركة البيانات</h3>
        <p className="tajawal modal-p">
          نحن لا نشارك بياناتك الشخصية مع أطراف أخرى. قد يتم نقل البيانات
          المجمعة والمجهولة الهوية بشكل دوري إلى خدمات خارجية لمساعدتنا في تحسين
          الموقع الإلكتروني والخدمة.
        </p>

        <h3 className="tajawal modal-headline">ملفات تعريف الارتباط</h3>
        <p className="tajawal modal-p">
          عند استخدامك للخدمات، قد نرسل ملفًا أو أكثر من ملفات تعريف الارتباط
          إلى جهاز الكمبيوتر أو جهاز المحمول الخاص بك. ملفات تعريف تساعدنا على
          تحسين خدماتنا وتحسين تجربة استخدامك للموقع، ومعرفة التفاعل و الميزات
          التي يفضلها المستخدمون على موقعنا. قد نستخدم كلًا من ملفات تعريف
          ارتباط الجلسة وملفات تعريف الارتباط الدائمة. يختفي ملف تعريف ارتباط
          الجلسة بعد إغلاقك للمتصفح الخاص بك. ويظل ملف تعريف الارتباط الدائم بعد
          إغلاقك للمتصفح الخاص بك، ويمكن استخدامه من قِبَل متصفحك في الزيارات
          اللاحقة للخدمات.
        </p>

        <p className="tajawal modal-p">
          يمكن إزالة ملفات تعريف الارتباط الدائمة. يُرجى مراجعة ملف "المساعدة"
          في متصفح الويب الخاص بك لمعرفة الطريقة المناسبة لتعديل إعدادات ملفات
          تعريف الارتباط. وإذا قمت بحذف ملفات تعريف الارتباط من الخدمات أو اخترت
          عدم قبولها، فقد لا تتمكن من استخدام مميزات الخدمات إلى أقصى إمكاناتها.
        </p>

        <h3 className="tajawal modal-headline">
          عدم المشاركة، تفضيلات الاتصال
        </h3>
        <p className="tajawal modal-p">
          يمكنك تعديل تفضيلات الاتصال و/أو إلغاء اتصالات محددة في أي وقت بما
          يلائمك.
        </p>

        <h3 className="tajawal modal-headline">التزامنا بأمان البيانات</h3>
        <p className="tajawal modal-p">
          نستخدم بعض الإجراءات الوقائية الفنية المصممة لحماية وأمن معلوماتك. ومع
          ذلك لا يمكننا ضمان أمن أي معلومات ترسلها إلينا من خلال الخدمات أو
          تخزنها على الموقع وأنت تقوم بذلك على مسئوليتك الخاصة. ولا يمكننا أيضًا
          ضمان عدم إمكانية الوصول إلى هذه المعلومات أو الكشف عنها أو تغييرها أو
          تدميرها عن طريق مخالفة أي من الضمانات الفنية الخاصة بنا.
        </p>

        <h3 className="tajawal modal-headline">حذف معلومات الحساب</h3>
        <p className="tajawal modal-p">
          لديك أيضًا الحق في حذف حسابك. يمكنك مراسلتنا بالايميل على
          support@hassala.app وإبلاغنا برغبتك في حذف حسابك. قد نحتفظ ببيانات
          المستخدم بعد طلب الحذف الخاص بك لفترة زمنية محددة قانونًا بما يتوافق
          مع أي متطلبات قانونية أو تنظيمية محلية. بعد طلب حذف الحساب، نحذف
          بيانات المستخدم، ما لم يكن يجب الاحتفاظ بها بسبب المتطلبات القانونية
          أو التنظيمية، لأغراض أمن معلومات ومنع الاحتيال. يتم حذف البيانات بشكل
          عام في غضون 3 أيام عمل من تاريخ طلب الحذف، إلا إذا كان الاحتفاظ
          ضروريًا للأسباب المذكورة أعلاه.
        </p>

        <h3 className="tajawal modal-headline">تغيير وتعديل سياسة الخصوصية</h3>
        <p className="tajawal modal-p">
          يجوز لنا تعديل سياسة الخصوصية هذه من وقت لآخر. يخضع استخدام المعلومات
          التي نجمعها الآن لسياسة الخصوصية السارية وقت استخدام هذه المعلومات.
          إذا قمنا بإجراء تغييرات كبيرة في الطريقة التي نجمع بها المعلومات أو
          نستخدمها، فسنخطرك عن طريق نشر إعلان على www.hassala.app أو إرسال بريد
          إلكتروني إليك.
        </p>
      </Typography>
    </div>
  );
};

export default PrivacyPolicyComponent;
