import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { FiX } from "react-icons/fi";

const InfoModal = ({ open, onClose, heading, content }) => {
  // Function to format the content and preserve newlines (empty lines)
  const formatContent = (content) => {
    return content.split("\n").map((line, index) => {
      if (line.trim() === "") {
        return <br key={index} />;
      }
      return <p key={index}>{line}</p>;
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="marhey" style={{ textAlign: "center" }}>
        {heading}
        <FiX
          style={{
            color: "#305F72",
            position: "absolute",
            right: "7.5vw",
            cursor: "pointer",
            backgroundColor: "#ebedf5",
            borderRadius: 100,
            padding: 3,
          }}
          size={"5vw"}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>
        {/* Render formatted content */}
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
