import React from "react";
import "./score.styles.css";
import { Typography } from "@mui/material";

function SummaryScorePage({ isFinished, totalPoints }) {
  return (
    <div className="score-base">
      {isFinished ? (
        <Typography
          variant="h5"
          component={"h5"}
          className="marhey dark"
          dir="rtl"
        >
          كسبت قبل كدة {totalPoints} نقطة عشان خلصت الاختبار.
        </Typography>
      ) : (
        <>
          <Typography
            variant="h6"
            component={"h6"}
            className="marhey dark"
            dir="rtl"
          >
            كسبت
          </Typography>
          <Typography
            variant="h4"
            component={"h4"}
            className="marhey dark"
            dir="rtl"
          >
            {totalPoints} نقطة!
          </Typography>
          <div className="padding" />
          <Typography
            variant="h6"
            component={"h6"}
            className="marhey primary"
            dir="rtl"
          >
            كسبت {totalPoints} نقطة للحصول على {totalPoints} إجابات الصحيحة في
            الاختبار
          </Typography>
        </>
      )}
    </div>
  );
}

export default SummaryScorePage;
