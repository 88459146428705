import React from "react";
import "./styles.css";
import { Typography } from "@mui/material";
const UseCasesCard = ({
  title,
  description,
  titleAr,
  descriptionAr,
  locale,
}) => {
  return (
    <div className="useCasesCard">
      <div className="useCasesCardHeader">
        <div className="useCasesCardHeaderDot"></div>
        <div className="useCasesCardHeaderDot"></div>
        <div className="useCasesCardHeaderDot"></div>
      </div>
      <div className="useCasesCardBase">
        <Typography
          variant="h5"
          style={{ fontWeight: "700", fontSize: "1.3em" }}
          className={`useCasesCardTitle ${locale}`}
        >
          {locale == "ar" ? titleAr : title}
        </Typography>
        <p
          // variant="h6"
          className={`useCasesCardDescription ${locale}`}
          style={
            locale === "ar"
              ? { direction: "rtl", textAlign: "right" }
              : { direction: "ltr", textAlign: "left" }
          }
        >
          {locale == "ar" ? descriptionAr : description}
        </p>
      </div>
    </div>
  );
};

export default UseCasesCard;
