import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { BottomNavigationBar, TopNavigationBar } from "../../components";
import { SettingsApis } from "../../api"; // Ensure you have this import for the API calls
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const [password, setPassword] = useState(""); // State for current password
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const validatePasswords = () => {
    if (password.length < 8) {
      setError("يرجى إدخال كلمة مرور حالية لا تقل عن 8 أحرف");
      return false;
    }
    if (newPassword.length < 8) {
      setError("يرجى إدخال كلمة مرور جديدة لا تقل عن 8 أحرف");
      return false;
    }
    setError("");
    return true; // Both passwords are valid
  };

  const handleSubmit = async () => {
    if (!validatePasswords()) {
      return; // Exit if passwords are not valid
    }

    setLoading(true); // Start loader

    try {
      // Send current and new passwords to API
      await SettingsApis.editSettings({ password, newPassword });
      toast.success("تم تحديث كلمة المرور بنجاح", {
        position: "top-right",
      });
    } catch (err) {
      toast.error("فشل تحديث كلمة المرور. حاول مرة أخرى.", {
        position: "top-right",
      });
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <>
      <TopNavigationBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          padding: 2,
          textAlign: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            borderRadius: 2,
            width: "90%", // Responsive width
            maxWidth: 400, // Max width for larger screens
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{ marginBottom: 2 }}
            className="marhey"
          >
            غير كلمة السر
          </Typography>
          <TextField
            label="كلمة السر الحالية"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            helperText={error.includes("الحالية") ? error : ""}
            fullWidth
            margin="normal"
          />
          <TextField
            label="كلمة السر الجديدة"
            variant="outlined"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!error}
            helperText={error.includes("الجديدة") ? error : ""}
            fullWidth
            margin="normal"
          />
          <Button
            className="current-button"
            onClick={handleSubmit}
            style={{ margin: "auto", width: "80%", position: "relative" }}
          >
            {loading ? (
              <CircularProgress size={24} color="warning" />
            ) : (
              <Typography
                variant="body1"
                fontWeight={"700"}
                color="white"
                style={{ transform: "translateY(3px)" }}
                className="tajawal"
              >
                تأكيد
              </Typography>
            )}
          </Button>
        </Paper>
      </Box>
      <BottomNavigationBar />
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
