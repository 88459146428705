import { Link } from "react-scroll";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import Hamburger from "hamburger-react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
const image = require("./bank-illustration-transparent.png");
const Header = ({ aboutRef, whyMahaRef, useCaseRef, locale = "ar" }) => {
  const matches = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const scrollToRef = (ref) => {
    setAnchorEl(null);
    ref.current.scrollIntoView();
  };
  return (
    <div
      className="info_header"
      style={{
        display: "flex",
        flexDirection: locale === "ar" ? "row-reverse" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "5vw",
        paddingRight: "5vw",
        zIndex: 22,
      }}
    >
      <div className="logo-container">
        {locale === "ar" ? (
          <>
            <h1 className="marhey dark">حصالة</h1>
            <img src={image} className="about-logo" alt="About image" />
          </>
        ) : (
          <>
            <img src={image} className="about-logo" alt="About image" />
            <h1 className="marhey dark">حصالة</h1>
          </>
        )}
        <Button
          className={
            locale === "ar"
              ? "navigate-to-login-btn-ar"
              : "navigate-to-login-btn"
          }
          onClick={() => navigate("/login")}
        >
          <Typography
            variant="body2"
            fontWeight={"700"}
            color="white"
            className=" navigate-to-login-btn-text"
            style={{
              textTransform: "none",
            }}
          >
            {locale === "en" ? "Login" : "تسجيل الدخول"}
          </Typography>
        </Button>
      </div>
      {matches ? (
        <nav
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Link style={{ cursor: "pointer" }} to="about" activeClass="active">
            {locale === "en" ? "About" : "عن حصالة"}
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            to="why-hassala"
            activeClass="active"
          >
            {locale === "en" ? "Why Hassala" : "لماذا حصالة"}
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            to="use-cases"
            activeClass="active"
          >
            {locale === "en" ? "Use Cases" : "حالات الاستخدام"}
          </Link>
        </nav>
      ) : (
        <>
          <h5
            className={`${locale === "en" ? "marhey" : ""} dark`}
            style={
              locale == "ar" ? { marginRight: "auto" } : { marginLeft: "auto" }
            }
            onClick={() => {
              localStorage.setItem("locale", locale === "ar" ? "en" : "ar");
              window.location.reload();
            }}
          >
            {locale == "ar" ? "ENGLISH" : "عربي"}
          </h5>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Hamburger color="black" toggled={open} toggle={handleClick} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{
              width: "50%",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={
                locale === "ar"
                  ? { color: "#305F72", direction: "rtl", textAlign: "right" }
                  : { color: "#305F72", direction: "ltr", textAlign: "left" }
              }
              onClick={() => scrollToRef(aboutRef)}
            >
              {locale === "en" ? "About" : "عن حصالة"}
            </MenuItem>
            <MenuItem
              style={
                locale === "ar"
                  ? { color: "#305F72", direction: "rtl", textAlign: "right" }
                  : { color: "#305F72", direction: "ltr", textAlign: "left" }
              }
              onClick={() => scrollToRef(whyMahaRef)}
            >
              {locale === "en" ? "Why Hassala" : "لماذا حصالة"}
            </MenuItem>
            <MenuItem
              style={
                locale === "ar"
                  ? { color: "#305F72", direction: "rtl", textAlign: "right" }
                  : { color: "#305F72", direction: "ltr", textAlign: "left" }
              }
              onClick={() => scrollToRef(useCaseRef)}
            >
              {locale === "en" ? "Use Cases" : "حالات الاستخدام"}
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Header;
