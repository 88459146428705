import React, { useState } from "react";
import GameBoard from "./GameBoard";
import Keyboard from "./Keyboard";
import { Typography, Box } from "@mui/material";
import { FiX } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

const WORD_OF_THE_DAY = "كلمتي"; // Example Arabic word; replace with your logic to set the daily word

const WotdPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [guesses, setGuesses] = useState(Array(6).fill("")); // Initialize empty guesses
  const [currentGuess, setCurrentGuess] = useState(Array(5).fill("")); // Array to track each letter of the current guess
  const [guessIndex, setGuessIndex] = useState(0); // Current guess row index
  const [gameStatus, setGameStatus] = useState("playing"); // Track the game status
  const [results, setResults] = useState(Array(6).fill([])); // Store the status of each guess box
  const [keyStatuses, setKeyStatuses] = useState({}); // Store the status of each key

  // Function to handle key presses
  const handleKeyPress = (key) => {
    if (gameStatus !== "playing") return; // Prevent input when the game is over

    if (key === "↵" && currentGuess.join("").length === 5) {
      // Check guess when Enter is pressed and the guess length is 5
      checkGuess();
    } else if (key === "⌫") {
      // Handle backspace, remove the rightmost letter from the current guess
      setCurrentGuess((prev) => {
        const indexToRemove = prev.lastIndexOf(
          prev.find((char) => char !== "")
        ); // Find the rightmost filled index
        if (indexToRemove >= 0) {
          const newGuess = [...prev];
          newGuess[indexToRemove] = ""; // Remove the character at the found index
          return newGuess;
        }
        return prev;
      });
    } else if (
      currentGuess.join("").length < 5 &&
      /^[\u0621-\u064A]+$/.test(key)
    ) {
      // Only add letters if the length is less than 5 and it's an Arabic character
      setCurrentGuess((prev) => {
        const indexToInsert = prev.lastIndexOf(""); // Find the rightmost empty index
        if (indexToInsert >= 0) {
          const newGuess = [...prev];
          newGuess[indexToInsert] = key; // Insert the character at the found index
          return newGuess;
        }
        return prev;
      });
    }
  };

  // Function to check the current guess against the target word
  const checkGuess = () => {
    const guess = currentGuess.join(""); // Join the array into a string
    const reversedWordOfTheDay = WORD_OF_THE_DAY.split("").reverse().join("");
    if (guess === reversedWordOfTheDay) {
      setGameStatus("won"); // Mark the game as won if the guess is correct
      navigate("/learn/score", {
        state: {
          isGame: true,
          totalQuizPoints: 50,
        },
      });
    } else if (guessIndex >= 5) {
      setGameStatus("lost"); // Mark the game as lost if out of guesses
    }

    // Save the guess and evaluate it
    setGuesses((prev) => {
      const newGuesses = [...prev];
      newGuesses[guessIndex] = guess; // Update the current guess row
      return newGuesses;
    });

    // Evaluate the guess to determine correct, present, and absent letters
    const evaluation = evaluateGuess(guess, reversedWordOfTheDay);
    setResults((prev) => {
      const newResults = [...prev];
      newResults[guessIndex] = evaluation; // Store the evaluation results
      return newResults;
    });

    // Update the key statuses based on evaluation
    updateKeyStatuses(guess, evaluation);

    // Reset the current guess and move to the next row
    setCurrentGuess(Array(5).fill(""));
    setGuessIndex((prev) => prev + 1);
  };

  // Function to evaluate each guess against the target word
  const evaluateGuess = (guess, target) => {
    const result = Array(5).fill("absent"); // Default all to absent
    const targetLetters = target.split(""); // Convert the target word to an array of letters

    // First pass to mark correct positions
    guess.split("").forEach((char, i) => {
      if (char === targetLetters[i]) {
        result[i] = "correct"; // Mark as correct
        targetLetters[i] = null; // Remove the matched letter to prevent double matching
      }
    });

    // Second pass to mark present letters that are misplaced
    guess.split("").forEach((char, i) => {
      if (result[i] !== "correct" && targetLetters.includes(char)) {
        result[i] = "present"; // Mark as present
        targetLetters[targetLetters.indexOf(char)] = null; // Remove the matched letter
      }
    });

    return result;
  };

  // Update key statuses based on the latest guess evaluation
  const updateKeyStatuses = (guess, evaluation) => {
    setKeyStatuses((prevStatuses) => {
      const newStatuses = { ...prevStatuses };
      guess.split("").forEach((char, i) => {
        const status = evaluation[i];
        // Update only if the current evaluation status is higher priority
        if (
          !newStatuses[char] ||
          status === "correct" ||
          (status === "present" && newStatuses[char] !== "correct")
        ) {
          newStatuses[char] = status;
        }
      });
      return newStatuses;
    });
  };

  return (
    <>
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" className="marhey wordle_title">
            كلمة اليوم
          </Typography>
          <FiX
            style={{
              color: "#305F72",
              marginLeft: "10px",
              cursor: "pointer",
              backgroundColor: "#ebedf5",
              borderRadius: 100,
              padding: 3,
            }}
            size={"5vw"}
            onClick={() => {
              navigate("/learn/summaries");
            }}
          />
        </Box>
        <GameBoard
          guesses={guesses}
          currentGuess={currentGuess}
          guessIndex={guessIndex}
          results={results}
        />
        <Keyboard onKeyPress={handleKeyPress} keyStatuses={keyStatuses} />
        {gameStatus === "won" && (
          <Typography variant="h6" color="success.main" dir="rtl">
            مبروك إنت جبت الكلمة صح!
          </Typography>
        )}
        {gameStatus === "lost" && (
          <Typography variant="h6" color="error.main" dir="rtl">
            ماجبتش الكلمة للأسف. كلمة إنهردة: {WORD_OF_THE_DAY}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default WotdPage;
