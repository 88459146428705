import React, { useEffect } from "react";
import "./score.styles.css";
import { TopNavigationBar } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import TutorialScorePage from "./tutorial-score.page";
import SummaryScorePage from "./summary-score.page";
import Confetti from "./confetti.json";
import Coin from "./coin.json";
import Lottie from "react-lottie";
import GameScorePage from "./game-score.page";

function ScorePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isFinished,
    isTutorial,
    isGame,
    tutorialQuizPoints,
    tutorialBasePoints,
    totalQuizPoints,
  } = location.state;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isTutorial) {
        navigate("/learn/tutorials");
      } else if (isGame) {
        navigate("/activity/search", {
          state: {
            isFinished: true
          }
        });
      } else {
        navigate("/learn/summaries");
      }
    }, 6000);

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, [isTutorial, navigate]);

  // Common style for the coin animation
  const coinStyle = {
    position: "absolute",
    zIndex: 400,
    left: "42.5%",
    animation: `moveToTopLeft 1.5s forwards`,
  };

  // Function to create multiple coin Lottie animations with different delays
  const renderCoinAnimations = (numCoins) => {
    const coins = [];
    for (let i = 0; i < numCoins; i++) {
      coins.push(
        <Lottie
          key={i}
          style={{ ...coinStyle, animationDelay: `${3.5 + i * 0.25}s` }}
          options={{
            loop: true,
            autoplay: true,
            animationData: Coin,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={"5vh"}
          width={"5vh"}
          isStopped={false}
          isPaused={false}
        />
      );
    }
    return coins;
  };

  return (
    <div className="score-root">
      <TopNavigationBar
        addedPoints={
          isFinished
            ? 0
            : Number(totalQuizPoints ?? 0) +
              Number(tutorialBasePoints ?? 0) +
              Number(tutorialQuizPoints ?? 0)
        }
      />

      {/* Confetti animation */}
      <Lottie
        style={{
          position: "absolute",
          top: 0,
          width: "100vw",
          zIndex: 2000,
        }}
        options={{
          loop: false,
          autoplay: true,
          animationData: Confetti,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"100vh"}
        width={"100vw"}
        isStopped={false}
        isPaused={false}
      />

      {/* Render Coin animations */}
      {!isFinished ? (
        <div className="coin-base">{renderCoinAnimations(7)}</div>
      ) : null}

      {/* Conditional rendering of score page based on isTutorial */}
      <div className="score">
        {isTutorial ? (
          <TutorialScorePage
            isFinished={isFinished}
            totalPoints={tutorialBasePoints + tutorialQuizPoints}
            tutorialBasePoints={tutorialBasePoints}
            tutorialQuizPoints={tutorialQuizPoints}
          />
        ) : isGame ? (
          <GameScorePage totalPoints={totalQuizPoints} />
        ) : (
          <SummaryScorePage
            isFinished={isFinished}
            totalPoints={totalQuizPoints}
          />
        )}
      </div>
    </div>
  );
}

export default ScorePage;
