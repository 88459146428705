import React from "react";
import "./score.styles.css";
import { Typography } from "@mui/material";

function GameScorePage({ totalPoints }) {
  return (
    <div className="score-base">
      <Typography
        variant="h4"
        component={"h4"}
        className="marhey dark"
        dir="rtl"
      >
        كسبت
      </Typography>
      <Typography
        variant="h4"
        component={"h4"}
        className="marhey dark"
        dir="rtl"
      >
        {totalPoints} نقطة!
      </Typography>
      <div className="padding" />
      <Typography
        variant="h6"
        component={"h6"}
        className="marhey primary"
        dir="rtl"
      >
        كسبت {totalPoints} نقطة عشان جبت كلمة اليوم
      </Typography>
    </div>
  );
}

export default GameScorePage;
