// src/components/GameBoard.js
import React from "react";
import { Box } from "@mui/material";

// Define styles for the box colors
const getBoxStyle = (status) => {
  switch (status) {
    case "correct":
      return { backgroundColor: "#6aaa64", color: "#fff" }; // Green for correct
    case "present":
      return { backgroundColor: "#c9b458", color: "#fff" }; // Yellow for present
    case "absent":
      return { backgroundColor: "#787c7e", color: "#fff" }; // Gray for absent
    default:
      return { backgroundColor: "#fff", border: "2px solid #d3d6da" }; // Default style
  }
};

const GameBoard = ({ guesses, currentGuess, guessIndex, results }) => {
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "grid",
        gridTemplateRows: "repeat(6, 60px)",
        gap: "10px",
        margin: "20px 0",
      }}
    >
      {guesses.map((guess, rowIndex) => (
        <Box
          key={rowIndex}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 60px)",
            gap: "10px",
            direction: "rtl", // Set the direction to right-to-left for Arabic style
          }}
        >
          {[...Array(5)].map((_, colIndex) => {
            const reversedIndex = 4 - colIndex; // Reverse the column index for RTL

            const char =
              rowIndex === guessIndex
                ? currentGuess[reversedIndex] || "" // Show the current guess from right to left
                : guess[reversedIndex] || ""; // Show previous guesses from right to left

            // Get the box style based on the result of each guess
            const status = results[rowIndex]
              ? results[rowIndex][reversedIndex]
              : null;

            return (
              <Box
                key={colIndex}
                sx={{
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  textTransform: "uppercase",
                  ...getBoxStyle(status),
                }}
              >
                {char}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default GameBoard;
