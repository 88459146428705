import React from "react";
import { Typography } from "@mui/material";
import PastWinners from "../../components/pastWinners/pastWinners";

const DialogContentComponent = ({ content }) => {
  const renderContent = () => {
    if (content === "HOW_TO_WIN") {
      return (
        <Typography className="tajawal modal-p">
          <p className="tajawal modal-headline">إزاي تكسب؟ 🎁</p>
          <p className="tajawal modal-p">
            مع كل خطوة بتعملها، بتزيد فرصتك في الفوز بجوايز قيمة! كل اللي عليك
            تعمله هو اتباع الخطوات دي:
          </p>
          <p className="tajawal modal-headline">كمّل الدروس كلها 📚</p>
          <p className="tajawal modal-p">
            اتعلّم مهارات مالية مهمة من خلال الدروس بتاعتنا. كل درس بتكمله هتجمع
            بيه نقط.
          </p>
          <p className="tajawal modal-headline">اختبر معلوماتك 📝</p>
          <p className="tajawal modal-p">
            جاوب على الأسئلة و كل ما تجاوب صح أكتر، تكسب نقط أكتر!
          </p>
          <p className="tajawal modal-headline">اجمع ميدالياتك 🏅</p>
          <p className="tajawal modal-p">
            لما تجمع 200 نقطة، هتكسب ميدالية برونزية.
          </p>
          <p className="tajawal modal-p">
            لو جمعت 300 نقطة، هتحصل على ميدالية فضية.
          </p>
          <p className="tajawal modal-p">
            ولو حققت 400 نقطة، هتكسب ميدالية ذهبية.
          </p>
          <p className="tajawal modal-headline">السحب الأسبوعي 🏆</p>
          <p className="tajawal modal-p">
            مش محتاج تعمل أي حاجة إضافية! بمجرد ما تجمع نقاطك وتحقق ميدالية،
            هتدخل السحب كل أسبوع تلقائيًا:
          </p>
          <p className="tajawal modal-p">ميدالية برونزية: السحب على 1,000ج.</p>
          <p className="tajawal modal-p">ميدالية فضية: السحب على 2,000ج.</p>
          <p className="tajawal modal-p">ميدالية ذهبية: السحب على 3,000ج.</p>
          <p className="tajawal modal-p">
            الجوايز هتبقى كروت هدايا، والفايزين يقدروا يختاروا الكروت اللي
            عايزينها بالقيمة اللي كسبوها!
          </p>
          <p className="tajawal modal-headline">احتفل بفوزك! 🥳</p>
          <p className="tajawal modal-p">
            بنعلن عن الفائزين كل أسبوع، ولو كسبت، هنبعتلك الإيميل علشان تختار
            جايزتك.
          </p>
        </Typography>
      );
    } else if (content === "PAST_WINNERS") {
      return <PastWinners />;
    }
    return null;
  };

  return <>{renderContent()}</>;
};

export default DialogContentComponent;
