import React from "react";
import { Typography } from "@mui/material";

const TermsOfServiceComponent = () => {
  return (
    <div className="tajawal">
      <Typography className="tajawal modal-p">
        <p className="tajawal modal-p">
          تحدد وتشكل شروط الاستخدام هذه، إلى جانب سياسة خصوصية شركة حصالة،
          الشروط والأحكام ("الشروط") التي تنطبق على وصولك واستخدامك لجميع خدمات
          حصالة (يشار إليها مجتمعة بـ "خدمات حصالة"، التي يتم توفيرها من خلال
          مواقعنا الإلكترونية بما في ذلك على سبيل المثال لا الحصر
          www.hassala.app وكذلك من خلال تطبيق الهاتف المحمول القابل للتحميل (إن
          وجد) (يشار إليها مجتمعة بـ "الموقع"). ويشمل تعريف "حصالة" شركة حصالة
          تكنولوجي ش.ذ.م.م. ومديريها، وأعضاء مجلس إدارتها، وموظفيها، ومستشاريها،
          والشركات التابعة لها، وفروعها، ووكلائها.
        </p>

        <h3 className="tajawal modal-headline">التسجيل وأمن الحساب</h3>
        <p className="tajawal modal-p">
          لاستخدام الخدمات، يجب (1) ألا يقل سنك عن ثمانية عشر عاماً (18)، (2) لم
          يتم تعليق أو إزالة أو منع تقديم أي من الخدمات من قبل، و(3) التسجيل في
          الخدمات واستخدامها وفقًا لجميع القوانين واللوائح المعمول بها والمطبقة.
        </p>

        <p className="tajawal modal-p">
          تسجيل الحساب: للوصول وإستخدام بعض مميزات الخدمات، قد تتم مطالبتك
          بالتسجيل للحصول على حساب. عندما تقوم بالتسجيل للحصول على حساب، قد نطلب
          منك تزويدنا بمعلومات تعريف معينة، بما في ذلك على سبيل المثال لا الحصر،
          عنوان بريدك الإلكتروني ومعلومات الاتصال الأخرى، وإنشاء اسم مستخدم
          وكلمة مرور ("معلومات التسجيل").
        </p>

        <h3 className="tajawal modal-headline">الملكية الفكرية ومحتوى المستخدم</h3>
        <p className="tajawal modal-p">
          حصالة وخدمات حصالة محمية بموجب حقوق الطبع والنشر المعمول بها وقوانين
          الملكية الفكرية الأخرى، ولا يجوز نسخ أي مواد من الخدمات، أو إعادة
          إصدارها، أو إعادة نشرها، أو رفعها، أو إرسالها، أو نقلها، أو توزيعها
          بأي شكل من الأشكال دون إذن صريح منا. جميع العلامات التجارية وعلامات
          الخدمات والإسم التجاري مملوكة لحصالة، باستثناء العلامات التجارية أو
          علامات الخدمة الخاصة بطرف ثالث، والتي هي ملك لأصحابها المعنيين.
        </p>

        <h3 className="tajawal modal-headline">خدمات الطرف الثالث</h3>
        <p className="tajawal modal-p">
          المشورة من أطراف ثالثة: بعض الخدمات قد تتضمن محتوى ومنتجات من أطراف
          ثالثة. أنت توافق على أن حصالة غير مسئولة عن أي مشورة، أو خدمة، أو منتج
          مقدم من أطراف ثالثة. وأنت توافق على أنك مسئول عن البحوث المالية
          والقرارات المالية الخاصة بك، وأن حصالة ليست مسئولة عن أي قرارات أو
          إجراءات تتخذها أو تفوض أطراف ثالثة لاتخاذها نيابة عنك على أساس
          المعلومات التي تتلقاها كمستخدم لحصالة.
        </p>

        <h3 className="tajawal modal-headline">السلوك المحظور</h3>
        <p className="tajawal modal-p">أنت توافق على عدم:</p>
        <ul className="tajawal modal-p">
          <li>
            استخدام الخدمات لأي غرض غير قانوني، أو في انتهاك لأي قانون أو لائحة؛
          </li>
          <li>
            انتهاك أو تشجيع الآخرين على انتهاك حقوق الغير، بما في ذلك حقوق
            الملكية الفكرية.
          </li>
          <li>
            نشر، أو رفع، أو توزيع أي محتوى غير قانوني، أو تشهيري، أو غير دقيق،
            أو يمكن لأي شخص عاقل أن يعتبره مرفوضًا، أو مسيء، أو بذيء، أو إباحي،
            أو مزعج، أو مهدد، أو كريه، أو غير ملائم بأي طريقة أخرى.
          </li>
          <li>
            التدخل في تشغيل أي مستخدم بالخدمات، بما في ذلك عن طريق الرفع أو نشر
            الفيروسات، أو برامج الإعلانات، أو برامج التجسس، أو الفيروسات
            المتنقلة، أو غيرها من التعليمات البرمجية الضارة.
          </li>
          <li>
            القيام بأي نشاط احتيالي، بما في ذلك انتحال شخصية أي شخص أو كيان.
          </li>
        </ul>

        <h3 className="tajawal modal-headline">التعويض</h3>
        <p className="tajawal modal-p">
          أنت توافق على أنك ستكون مسئولًا شخصيًا عن استخدامك للخدمات، وأنك توافق
          على الدفاع، وتعويض، وإبراء ذمة حصالة، ومسئوليها، وأعضاء مجلس إدارتها،
          وموظفيها، ومستشاريها، والشركات التابعة لها، وفروعها، ووكلائها من وضد
          أي وجميع المطالبات، والالتزامات، والأضرار، والخسائر، والمصروفات (بما
          في ذلك أتعاب المحاماة وأتعاب وتكاليف المحاسبين)، الناشئة عن أو
          المرتبطة بأي طريقة بـ (1) وصولك إلى الخدمات أو استخدامها؛ (2) انتهاكك
          للشروط أو أي قانون أو لائحة معمول بها؛ (3) انتهاكك لأي حق من حقوق طرف
          ثالث.
        </p>

        <h3 className="tajawal modal-headline">الإنهاء</h3>
        <p className="tajawal modal-p">
          إذا خالفت هذه الشروط، فسيتم تلقائيًا إنهاء تصريحك باستخدام الخدمات.
          بالإضافة إلى ذلك، يجوز لحصالة وفقًا لتقديرها الخاص تعليق حساب المستخدم
          الخاص بك أو إنهائه و/أو تعليق أو إنهاء بعض أو كل وصولك إلى الخدمات في
          أي وقت، مع أو بدون إشعار لك.
        </p>

        <h3 className="tajawal modal-headline">تعديل الشروط</h3>
        <p className="tajawal modal-p">
          تحتفظ حصالة بالحق في أي وقت في تعديل هذه الشروط وفرض شروط أو أحكام
          جديدة أو إضافية على استخدامك للخدمات. وتكون هذه التعديلات والشروط
          والأحكام الإضافية سارية على الفور وتُدمَج في هذه الشروط. ويعتبر
          استخدامك المستمر للخدمات التالية إشعار قبول لأي تعديلات على الشروط.
        </p>

        <h3 className="tajawal modal-headline">إخلاء المسئولية عن الضمانات</h3>
        <p className="tajawal modal-p">
          يتم تقديم الخدمات "كما هي" وعلى أساس "حسب إتاحتها"، دون أي ضمان أو شرط
          من أي نوع، صريحًا أو ضمنيًا.
        </p>

        <h3 className="tajawal modal-headline">حدود المسئولية</h3>
        <p className="tajawal modal-p">
          لن تكون حصالة مسئولة بأي حال من الأحوال عن أي أضرار عرضية، أو خاصة، أو
          تبعية، أو مباشرة، أو غير مباشرة، أو جزائية، سواء كانت تستند إلى
          الضمان، أو العقد (بما في ذلك الإهمال)، أو لائحة، أو أي أحكام قانونية
          أخرى.
        </p>

        <h3 className="tajawal modal-headline">القانون الواجب التطبيق</h3>
        <p className="tajawal modal-p">تخضع هذه الشروط لقوانين جمهورية مصر العربية.</p>

        <h3 className="tajawal modal-headline">تسوية المنازعات</h3>
        <p className="tajawal modal-p">
          توافق أنت و حصالة على الخضوع للولاية القضائية الحصرية للمحاكم المختصة
          في مصر لغرض التقاضي عن كل تلك المنازعات.
        </p>

        <h3 className="tajawal modal-headline">تعديل الخدمات</h3>
        <p className="tajawal modal-p">
          تحتفظ حصالة بالحق في تعديل أو إيقاف بعض الخدمات أو جميعها بشكلٍ مؤقت
          أو دائم في أي وقت دون أي إشعار أو التزام إضافي تجاهك.
        </p>

        <h3 className="tajawal modal-headline">بنود عامة</h3>
        <p className="tajawal modal-p">
          الاتفاق الكامل: تشكل هذه الشروط، إلى جانب سياسة الخصوصية، الفهم
          والاتفاق الكامل والحصري بينك وبين حصالة فيما يتعلق باستخدامك للخدمات
          والوصول إليها.
        </p>

        <p className="tajawal modal-p">
          عدم التنازل: لن يؤثر عدم المطالبة بأداء أي حكم من الأحكام على حقنا في
          طلب الأداء في أي وقت بعد ذلك، ولا يشكل التنازل عن أي مخالفة أو تقصير
          في الشروط تنازلًا عن أي مخالفة أو تقصير لاحق أو تنازل عن الحكم نفسه.
        </p>

        <p className="tajawal modal-p">
          شروط الطرف الثالث: في بعض الحالات، يطلب مقدمو خدمات من الطرف الثالث أن
          يوافق مستخدمو حصالة على الالتزام ببعض شروط الاستخدام الإضافية الخاصة
          بمنتجات أو خدمات معينة ("شروط الطرف الثالث"). وفي حالة وجود أي تعارض
          بين شروط الطرف الثالث وشروط استخدام حصالة، تسري شروط استخدام حصالة
          وتسود.
        </p>
      </Typography>
    </div>
  );
};

export default TermsOfServiceComponent;
