import { LOGIN_SUCCESS, USER_PROGRESS } from "./types";

export default class AuthReducer {
  static loginUser = (userData) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: userData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  static setUserProgress = (userProgress) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PROGRESS,
        payload: userProgress,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
