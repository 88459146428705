import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardStack from "../../components/cardStack/cardStack";
import "./rewards.styles.css";
import { FiX } from "react-icons/fi";

const RewardsPage = ({ rewards }) => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [currentReward, setCurrentReward] = useState(null);

  // Scroll to the top of the page when the component is first mounted
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const handleDialogOpen = (reward) => {
    setCurrentReward(reward);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentReward(null);
  };

  const renderNoRewards = () => (
    <Typography variant="h6" align="center" className="no-rewards">
      لا توجد جوائز متاحة
    </Typography>
  );

  const formatStepsText = (steps) => {
    return steps.split("\n").map((line, index) => {
      const isTabLine = line.includes("\t");
      return line.trim().length === 0 ? (
        <br key={index} />
      ) : (
        <Typography
          key={index}
          variant={isTabLine ? "body2" : "body1"}
          className={`tajawal reward-steps-line ${
            isTabLine ? "" : "reward-steps-line-bold"
          }`}
        >
          {isTabLine ? "\u00A0".repeat(4) : ""}
          {line.trim()}
        </Typography>
      );
    });
  };

  const renderRewardCard = (reward) => {
    return (
      <div key={reward._id} className="reward-card">
        <div className="reward-card-image">
          <img src={require(`./${reward.image}`)} alt={reward.name} />
        </div>
        <div className="reward-card-details">
          <div className="reward-card-header">
            <div className="reward-card-header-text-base">
              <Typography variant="body2" className="reward-name tajawal">
                {reward.name}
              </Typography>
              <Typography variant="h5" className="reward-organization marhey">
                {reward.organization}
              </Typography>
            </div>

            <Button
              className="current-button-3 btn-padding"
              onClick={() => handleDialogOpen(reward)}
            >
              <Typography
                variant="body2"
                fontWeight={"700"}
                color="white"
                className="tajawal cta-text"
              >
                الشروط
              </Typography>
            </Button>
          </div>
          {/* <Typography variant="body1" className="dotted">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
            . . . . . . . .
          </Typography> */}
          {/* <hr className="dotted" /> */}
          <Typography variant="body1" className="reward-description tajawal">
            {reward.description}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className="rewards-container">
      <CardStack
        title="جوائز"
        description="بدل النقط بتاعتك لجوايز مختلفة. جمع نقط أكتر عشان تكسب أكتر"
        image={require("../bank-illustration-transparent.png")}
      />

      <div className="rewards-listing">
        {rewards.length === 0
          ? renderNoRewards()
          : rewards.map(renderRewardCard)}
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="marhey reward-steps-title">
          {currentReward && currentReward.organization}
          <FiX
            style={{
              color: "#305F72",
              position: "absolute",
              right: "7.5vw",
              cursor: "pointer",
              backgroundColor: "#ebedf5",
              borderRadius: 100,
              padding: 3,
            }}
            size={"5vw"}
            onClick={handleDialogClose}
          />
        </DialogTitle>
        <DialogContent>
          {currentReward && (
            <>
              <Typography variant="body2" className="reward-price tajawal">
                الحد الادني: 💷
                {new Intl.NumberFormat().format(currentReward.price)}ج
              </Typography>
              <Typography
                variant="body2"
                className="reward-validity reward-validity-margin tajawal"
              >
                {currentReward.validity}
              </Typography>
              {currentReward.steps && formatStepsText(currentReward.steps)}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RewardsPage;
