import React, { useState, useMemo, useRef, useEffect } from "react";
import TinderCard from "react-tinder-card";
import "./tutorial.styles.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiChevronLeft, FiChevronRight, FiDelete, FiX } from "react-icons/fi";
import { EditorProvider } from "@tiptap/react";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import { TutorialsApis } from "../../api";

// import Underline from "@tiptap/extension-underline";

function TutorialPageDesktop() {
  const { tutorial_id } = useParams(); // Access the id parameter from the URL
  const location = useLocation();
  const item = location.state;
  const flashcardsStack = [...item.flashCards].reverse();

  const navigate = useNavigate();
  const answersFromStorage = localStorage.getItem("answers");
  const finishedTutorials = localStorage.getItem("finishedTutorials");
  const isTutorialFinished =
    JSON.parse(finishedTutorials).includes(tutorial_id);

  const initialize = () => {
    const parsedAnswers = JSON.parse(answersFromStorage);
    const tutorialAnswers = parsedAnswers[tutorial_id];

    if (tutorialAnswers) {
      const initAnswers = tutorialAnswers;
      Object.keys(initAnswers).map((cardId) => {
        const flashcard = flashcardsStack.find((card) => card._id === cardId);
        const answer = flashcard.answers.find(
          (answer) => answer._id === initAnswers[cardId]
        );

        initAnswers[cardId] = { ...answer, submitted: true };
        setAnswers(initAnswers);
      });
    }
  };
  const [answers, setAnswers] = useState({});
  useEffect(() => {
    initialize();
  }, [answersFromStorage]);

  const handleAnswerPick = (answer, cardId) => {
    const dict = { ...answers, [cardId]: { ...answer, submitted: false } };
    setAnswers(dict);
  };

  const [refresh, setRefresh] = useState(0);

  const handleAnswerSubmit = (cardId) => {
    let dict = answers;
    if (!dict[cardId]) {
      dict[cardId] = { skipped: true };
    }
    dict[cardId].submitted = true;
    setAnswers({ ...dict });
    setRefresh(refresh + 1);
  };

  const [currentIndex, setCurrentIndex] = useState(flashcardsStack.length - 1);
  const [lastDirection, setLastDirection] = useState();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(flashcardsStack.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canGoBack = currentIndex < flashcardsStack.length - 1;
  const canGoForward =
    flashcardsStack[currentIndex].order !== flashcardsStack.length;

  const canSwipe =
    currentIndex >= 0 &&
    flashcardsStack[currentIndex].order !== flashcardsStack.length;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (!canGoForward) return;
    if (canSwipe && currentIndex < flashcardsStack.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const handleNavigate = async () => {
    navigate("/learn/tutorials");
  };

  const handleTutorialFinish = async () => {
    let totalPoints = 0;
    if (!isTutorialFinished) {
      const { totalBonusPoints } = Object.values(answers).reduce(
        (prev, curr) => {
          prev.totalBonusPoints += curr.correct ? 1 : 0;
          return prev;
        },
        {
          totalBonusPoints: 0,
        }
      );
      totalPoints = totalBonusPoints;
      const mappedAnswers = Object.keys(answers).map((cardId) => {
        return {
          tutorialId: tutorial_id,
          cardId: cardId,
          answerId: answers[cardId]._id,
        };
      });

      const payload = {
        answers: mappedAnswers,
        tutorialId: tutorial_id,
        totalPoints: totalPoints + 30,
      };

      await TutorialsApis.finishTutorial(payload);
    }
    navigate("/learn/score", {
      state: {
        isFinished: isTutorialFinished,
        isTutorial: true,
        tutorialQuizPoints: totalPoints,
        tutorialBasePoints: 30,
      },
    });
  };

  return (
    <div className="cont">
      <div className="header">
        {flashcardsStack[currentIndex].type === "QUIZ" && (
          <div className="infoText-dt">جاوب على الاسئلة واكسب نقط</div>
        )}
        <FiX
          style={{
            color: "#fff",
            marginLeft: "auto",
            position: "absolute",
            right: 0,
          }}
          size={"3vw"}
          onClick={handleNavigate}
        />
      </div>
      <div className="cardContainer-dt">
        {flashcardsStack.map((character, index) => (
          <TinderCard
            ref={childRefs[index]}
            className="swipe"
            key={character._id}
            onSwipe={(dir) => swiped(dir, character._id, index)}
            onCardLeftScreen={() => outOfFrame(character._id, index)}
            preventSwipe={
              flashcardsStack[currentIndex].order === flashcardsStack.length ||
              (flashcardsStack[currentIndex].type === "QUIZ" &&
                (!answers[character._id] ||
                  (answers[character._id] &&
                    !answers[character._id].submitted)))
                ? ["left", "right", "up", "down"]
                : []
            }
          >
            <div className="card-dt">
              {character.type === "INFO" &&
              character.illustration &&
              character.imagePlacement === "top" ? (
                <img
                  src={character.illustration}
                  className="illustration"
                  style={
                    character.imageSize === "large"
                      ? { width: "20vw" }
                      : character.imageSize === "medium"
                      ? { width: "15vw" }
                      : { width: "10vw" }
                  }
                />
              ) : null}
              <div
                style={
                  character.type === "QUIZ"
                    ? {
                        borderRadius: 20,
                        backgroundColor: "rgba(240, 240, 240, 0.5)",
                        alignItems: "center",
                        marginVertical: 20,
                        width: "90%",
                        padding: 10,
                        justifyContent: "center",
                        textAlign: "right",
                        display: "flex",
                        fontWeight: "bolder",
                      }
                    : {
                        minHeight: "60%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "90%",
                      }
                }
              >
                {character.type === "QUIZ" ? (
                  <div
                    style={{
                      fontSize: "1.5vw",
                      fontWeight: "300",
                      color: "black",
                      padding: ".5vw",
                    }}
                  >
                    {character.content}
                  </div>
                ) : (
                  <EditorProvider
                    extensions={[
                      Color.configure({
                        types: [TextStyle.name, ListItem.name],
                      }),
                      TextStyle.configure({ types: [ListItem.name] }),
                      StarterKit.configure({
                        bulletList: {
                          keepMarks: true,
                          HTMLAttributes: { dir: "rtl" },
                          keepAttributes: false,
                        },
                        orderedList: {
                          HTMLAttributes: { dir: "rtl" },
                          keepMarks: true,
                          keepAttributes: false,
                        },
                      }),
                    ]}
                    content={character.content}
                  ></EditorProvider>
                )}
              </div>

              <div
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 20,
                  width: "90%",
                }}
              >
                {character.type === "QUIZ" &&
                  character.answers.length > 0 &&
                  character.answers.map((answer, index) => (
                    <button
                      key={index}
                      disabled={
                        answers[character._id] &&
                        answers[character._id].submitted
                      }
                      onTouchEnd={() => handleAnswerPick(answer, character._id)}
                      onClick={(e) => {
                        handleAnswerPick(answer, character._id);
                      }}
                      className={`${
                        answers[character._id] &&
                        answers[character._id]._id === answer._id &&
                        answers[character._id].submitted &&
                        !answers[character._id].correct
                          ? "incorrect-answer"
                          : answers[character._id] &&
                            // answers[character._id]._id === answer._id &&
                            (answers[character._id].submitted ||
                              answers[character._id].skipped) &&
                            answer.correct
                          ? "correct-answer"
                          : answers[character._id] &&
                            answers[character._id]._id === answer._id &&
                            !answers[character._id].submitted
                          ? "picked-answer"
                          : "unpicked-answer"
                      } answer`}
                      style={{
                        zIndex: 99,
                        backgroundColor: "rgba(240, 240, 240, 0.5)",
                        width: "90%",
                        borderRadius: 10,
                        margin: 15,
                      }}
                    >
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "1vw",
                          marginHorizontal: 10,
                          padding: ".5vw",
                        }}
                      >
                        {answer.content}
                      </div>
                    </button>
                  ))}
              </div>

              {character.type === "QUIZ" &&
                answers[character._id] &&
                answers[character._id].submitted &&
                !answers[character._id].skipped &&
                answers[character._id].correct && (
                  <div className="bonusText">!لقد فزت بنقطة إضافية</div>
                )}

              <div className="buttonRow">
                {character.type === "QUIZ" &&
                  (!answers[character._id] ||
                    (answers[character._id] &&
                      !answers[character._id].submitted)) && (
                    <button
                      onClick={() => handleAnswerSubmit(character._id)}
                      onTouchEnd={() => handleAnswerSubmit(character._id)}
                      className="popout"
                      style={{
                        margin: "auto",
                        marginBottom: 10,
                        backgroundColor: "#305F72",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "1.5vw",
                        }}
                      >
                        تخطى
                      </div>
                    </button>
                  )}

                {character.type === "QUIZ" &&
                answers[character._id] &&
                !answers[character._id].submitted ? (
                  <>
                    <button
                      onClick={() => handleAnswerSubmit(character._id)}
                      onTouchEnd={() => handleAnswerSubmit(character._id)}
                      className="popout"
                      style={{
                        margin: "auto",
                        marginBottom: 10,
                        backgroundColor: "#305F72",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "1.5vw",
                        }}
                      >
                        جاوب
                      </div>
                    </button>
                  </>
                ) : null}
              </div>
              {character.type === "INFO" &&
              character.imagePlacement === "bottom" ? (
                <div>
                  <img
                    src={character.illustration}
                    className="illustration"
                    style={
                      character.imageSize === "large"
                        ? { width: "20vw" }
                        : character.imageSize === "medium"
                        ? { width: "15vw" }
                        : { width: "10vw" }
                    }
                  />
                </div>
              ) : null}
              {character.order === flashcardsStack.length ? (
                <>
                  <button
                    onClick={() => handleTutorialFinish()}
                    onTouchEnd={() => handleTutorialFinish()}
                    className="popout"
                    style={{
                      margin: "auto",
                      marginBottom: 10,
                      backgroundColor: "#305F72",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 10,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "1.5vw",
                      }}
                    >
                      {isTutorialFinished ? "عرض النتيجة" : "انهاء الدرس"}
                    </div>
                  </button>
                </>
              ) : null}
            </div>
          </TinderCard>
        ))}
      </div>
      <div className="buttons">
        <FiChevronLeft
          onClick={() => goBack()}
          size={"4vw"}
          style={{ color: !canGoBack ? "grey" : "#fff" }}
        />
        <FiChevronRight
          onClick={() => swipe("right")}
          size={"4vw"}
          style={{ color: !canGoForward ? "grey" : "#fff" }}
        />
      </div>
    </div>
  );
}

export default TutorialPageDesktop;
