import React, { useState, useEffect } from "react";
import "./topNavbar.styles.css";
import { useNavigate } from "react-router-dom";
import PointsDisplay from "./PointsDisplay";
import UserBadge from "./UserBadge";
import SettingsMenu from "./SettingsMenu";
import { getColor } from "../../../utils";

const TopNavigationBar = ({ addedPoints = 0 }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const totalPoints = localStorage.getItem("totalPoints");
  const [newTotalPoints, setNewTotalPoints] = useState(totalPoints);
  const [userBadge, setUserBadge] = useState("blue");

  useEffect(() => {
    if (addedPoints !== 0) {
      const newPoints = +newTotalPoints + +addedPoints;
      setNewTotalPoints(newPoints);
      const badge = getColor(newPoints);
      setUserBadge(badge);
    }
  }, [addedPoints]);

  useEffect(() => {
    const badge = getColor(newTotalPoints);
    setUserBadge(badge);
  }, []);

  return (
    <nav className="top-nav">
      <div className="top-nav-container">
        <PointsDisplay totalPoints={totalPoints} addedPoints={addedPoints} />
        <UserBadge userBadge={userBadge} />
      </div>
      <SettingsMenu
        anchorEl={anchorEl}
        open={open}
        handleMenuClick={(e) => setAnchorEl(e.currentTarget)}
        handleMenuClose={() => setAnchorEl(null)}
        navigate={navigate}
      />
    </nav>
  );
};

export default TopNavigationBar;
