import React from "react";
import "./user.styles.css"; // Styles for MedalsCard
import { Typography } from "@mui/material";

const DrawCountdown = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = endDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        أيام: Math.floor(difference / (1000 * 60 * 60 * 24)) || 0,
        ساعات: Math.floor((difference / (1000 * 60 * 60)) % 24) || 0,
        دقائق: Math.floor((difference / 1000 / 60) % 60) || 0,
        ثواني: Math.floor((difference / 1000) % 60) || 0,
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    return (
      <div key={index} className="countdown_interval">
        <Typography
          variant="h5"
          className="marhey"
          style={{
            color: "#305f72",
          }}
        >
          {timeLeft[interval]}
        </Typography>
        <Typography
          variant="body2"
          className="tajawal"
          style={{
            color: "#305f72",
          }}
        >
          {interval}
        </Typography>
      </div>
    );
  });

  return (
    <>
      <div className="countdown_base_gradient">
        <div className="countdown_base">
          <h3 className="streak-title marhey" style={{ visibility: "hidden" }}>
            فاضل على السحب
          </h3>
          {timerComponents.length ? (
            <div className="countdown_interval_base">{timerComponents}</div>
          ) : (
            <span>Time's up!</span>
          )}
        </div>
      </div>
    </>
  );
};

export default DrawCountdown;
