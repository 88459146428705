// src/components/Keyboard.js
import React from "react";
import { Box, Button } from "@mui/material";

const keys = [
  "ض",
  "ص",
  "ث",
  "ق",
  "ف",
  "غ",
  "ع",
  "ه",
  "خ",
  "ح",
  "ج",
  "د",
  "ش",
  "س",
  "ي",
  "ب",
  "ل",
  "ا",
  "ت",
  "ن",
  "م",
  "ك",
  "ط",
  "ذ",
  "ئ",
  "ء",
  "ؤ",
  "ر",
  "ى",
  "ة",
  "و",
  "ز",
  "ظ",
  "⌫",
  "↵",
];

const Keyboard = ({ onKeyPress, keyStatuses }) => {
  // Function to determine the background color based on key status
  const getKeyColor = (key) => {
    const status = keyStatuses[key];
    switch (status) {
      case "correct":
        return "#6aaa64"; // Green
      case "present":
        return "#c9b458"; // Yellow
      case "absent":
        return "#787c7e"; // Gray
      default:
        return "#d3d6da"; // Default light gray
    }
  };

  // Function to determine the text color based on key status
  const getTextColor = (key) => {
    const status = keyStatuses[key];
    if (status === "absent") return "#fff"; // White text for gray background
    if (status === "present" || status === "correct") return "#fff"; // White text for colored backgrounds
    return "#000"; // Black text for default light gray
  };

  return (
    <Box
      sx={{
        display: "grid",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(7, 1fr)",
        gap: 1,
        margin: "20px",
      }}
    >
      {keys.map((key) => (
        <Button
          key={key}
          variant="contained"
          onClick={() => onKeyPress(key)}
          style={{
            backgroundColor: getKeyColor(key),
            color: getTextColor(key),
          }}
          sx={{
            minWidth: key === "⌫" || key === "↵" ? "60px" : "40px",
            padding: "10px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {key}
        </Button>
      ))}
    </Box>
  );
};

export default Keyboard;
