import "./bottomNavbar.styles.css";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import image from "./image.png";
import tutorialsActiveImage from "./lessons.gif";
import tutorialsInativeImage from "./tutorials_inactive.png";
import accountImage from "./account.png";
import accountInactiveImage from "./account_inactive.png";
import Lottie from "react-lottie";
import ActiveRewardsLottie from "./gift.json";
import InactiveRewardsLottie from "./gift_inactive.json";

import ActiveSummariesLottie from "./summaries_active.json";
import InactiveSummariesLottie from "./summaries_inactive.json";

import ActiveAvatarLottie from "./avatar_active.json";
import InactiveAvatarLottie from "./avatar_inactive.json";

import summariesImage from "./summaries.png";
import summariesInactiveImage from "./summaries_inactive.png";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import useHaptic from "../../../hooks/useHaptic";

const BottomNavigationBar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default install prompt
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); // Show the custom install prompt
    };

    const handleAppInstalled = () => {
      console.log("PWA was installed!");
      setIsInstallable(false); // Hide install prompt if app is installed
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the native install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the installation");
        } else {
          console.log("User dismissed the installation prompt");
        }
        setDeferredPrompt(null); // Reset the deferred prompt after user interaction
        setIsInstallable(false); // Hide the custom prompt
      });
    }
  };
  const triggerVibration = useHaptic();
  const handleNavigate = (target) => {
    triggerVibration(100);
    navigate(target);
  };

  return isMobile ? (
    <div className="bottom-nav-base">
      {isInstallable ? (
        <div className="reminder-base">
          <Typography
            variant="body1"
            className="marhey"
            style={{ textAlign: "right" }}
          >
            ثبت حصالة على شاشة الرئيسية؟
          </Typography>
          <Button
            variant="contained"
            className="reminder-btn marhey"
            onClick={handleInstallClick}
          >
            <Typography variant="body2" className="marhey">
              ثبت
            </Typography>
          </Button>
        </div>
      ) : null}
      <nav className="bottom-nav">
        <button
          className="learn-button"
          style={{
            color: pathName.includes("/user") ? "#f0f4f8" : "grey",
          }}
          onClick={() => handleNavigate("/user")}
        >
          {pathName.includes("/user") ? (
            <Lottie
              style={{
                width: "7.5vw",
              }}
              options={{
                loop: false,
                autoplay: true,
                animationData: ActiveAvatarLottie,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={"7.5vw"}
              width={"7.5vw"}
              isStopped={false}
              isPaused={false}
            />
          ) : (
            <img
              src={require("./avatar_inactive.png")}
              className="icon-placeholder"
            />
          )}

          <Typography
            style={{
              color: pathName.includes("/user") ? "#9ebad9" : "#707070",
            }}
            variant="body2"
          >
            حسابى
          </Typography>
        </button>
        <button
          className="learn-button"
          onClick={() => handleNavigate("/learn/tutorials")}
        >
          {pathName.includes("/learn/tutorials") ? (
            <img src={tutorialsActiveImage} className="icon-placeholder" />
          ) : (
            <img src={tutorialsInativeImage} className="icon-placeholder" />
          )}
          <Typography
            style={{
              color: pathName.includes("/learn/tutorials")
                ? "#9ebad9"
                : "#707070",
            }}
            variant="body2"
          >
            الأكاديمية
          </Typography>
        </button>
        <button
          className="learn-button"
          onClick={() => handleNavigate("/learn/summaries")}
        >
          {/* {pathName.includes("/learn/summaries") ? (
            <img src={summariesImage} className="icon-placeholder" />
          ) : (
            <img src={summariesInactiveImage} className="icon-placeholder" />
          )}{" "} */}

          {pathName.includes("/learn/summaries") ? (
            <Lottie
              style={{
                width: "7.5vw",
              }}
              options={{
                loop: false,
                autoplay: true,
                animationData: ActiveSummariesLottie,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={"7.5vw"}
              width={"7.5vw"}
              isStopped={false}
              isPaused={false}
            />
          ) : (
            <img
              src={require("./summary_inactive.png")}
              className="icon-placeholder"
            />
          )}
          <Typography
            style={{
              color: pathName.includes("/learn/summaries")
                ? "#9ebad9"
                : "#707070",
            }}
            variant="body2"
          >
            حصالة اليوم
          </Typography>
        </button>

        <button
          className="learn-button"
          onClick={() => handleNavigate("/rewards")}
        >
          {/* <Lottie
            style={{
              width: "7.5vw",
            }}
            options={{
              loop: false,
              autoplay: pathName.includes("/rewards") ? false : true,
              animationData: pathName.includes("/rewards")
                ? ActiveRewardsLottie
                : InactiveRewardsLottie,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"7.5vw"}
            width={"7.5vw"}
            isStopped={false}
            isPaused={pathName.includes("/rewards") ? false : true}
          /> */}
          {pathName.includes("/rewards") ? (
            <Lottie
              style={{
                width: "7.5vw",
              }}
              options={{
                loop: false,
                autoplay: true,
                animationData: ActiveRewardsLottie,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={"7.5vw"}
              width={"7.5vw"}
              isStopped={false}
              isPaused={false}
            />
          ) : (
            <img
              src={require("./gift_inactive.png")}
              className="icon-placeholder"
            />
          )}
          <Typography
            style={{
              color: pathName.includes("/rewards") ? "#9ebad9" : "#707070",
            }}
            variant="body2"
          >
            جوائز
          </Typography>
        </button>
      </nav>
    </div>
  ) : null;
};

export default BottomNavigationBar;
