import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import "./styles.css";

const CustomCard = ({
  title,
  description,
  image,
  zIndex,
  transform,
  boxShadow,
}) => {
  return (
    <Card
      className="custom-card"
      style={{
        zIndex,
        transform,
        boxShadow,
        width: zIndex === 3 ? "90vw" : zIndex === 2 ? "87.5vw" : "85vw",
      }}
    >
      <CardMedia component="img" className="card-media" image={image} />
      <CardContent className="card-content">
        <Typography variant="h4" className="title marhey bold-font">
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={`description tajawal ${
            zIndex === 3 ? "text-secondary" : "text-white"
          }`}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardStack = ({ title, description, image }) => {
  return (
    <div className="card-stack">
      <CustomCard
        title={title}
        description={description}
        image={image}
        zIndex={3}
        transform="translateY(-10px)"
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.3)"
      />
      <CustomCard
        title={title}
        description={description}
        image={image}
        zIndex={2}
        transform="translate(5px, -5px)"
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.2)"
      />
      <CustomCard
        title={title}
        description={description}
        image={image}
        zIndex={1}
        transform="translate(10px, 0px)"
        boxShadow="0 4px 10px rgba(0, 0, 0, 0.1)"
      />
    </div>
  );
};

export default CardStack;
