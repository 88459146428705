import React, { useEffect, useState } from "react";
import "./summaries.styles.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import "moment/locale/ar";

import { useNavigate } from "react-router-dom";
import CardStack from "../../components/cardStack/cardStack";
import { FiChevronLeft } from "react-icons/fi";
import { SummariesApis } from "../../api";

/*************  ✨ Codeium Command ⭐  *************/
/**
 * SummariesPage
 * @prop {array} summaries - array of summaries from the backend
 * @returns - a page containing a card stack, a current summary, a search bar, and a list of past summaries
 */
/******  e4308b83-6f1b-47a4-8e19-6cc71028cd73  *******/ const SummariesPage = ({
  summaries,
}) => {
  const navigate = useNavigate();
  const [currentSummary, setCurrentSummary] = useState({});
  const [pastSummaries, setPastSummaries] = useState([]);
  moment.locale("ar");

  const handleWordleNavigation = () => {
    navigate("/activity/wotd");
  };

  const initialize = async () => {
    if (summaries.length > 0) {
      const result = await SummariesApis.getSummaries();

      const currentSummary = result[0];
      const pastSummaries = [...result];
      pastSummaries.shift();

      setCurrentSummary(currentSummary);
      setPastSummaries(pastSummaries);
    }
  };

  useEffect(() => {
    if (summaries.length > 0) {
      const currentSummary = summaries[0];
      const pastSummaries = [...summaries];
      pastSummaries.shift();

      setCurrentSummary(currentSummary);
      setPastSummaries(pastSummaries);
    } else {
      initialize();
    }
  }, [summaries]);

  const handleSummaryNavigation = (summary) => {
    if (summary && summary._id) {
      navigate(`/learn/summary/${summary._id}`, {
        state: summary,
      });
    }
  };

  return (
    <div className="summaries-container">
      <CardStack
        title="حصالة اليوم"
        description=" ملخصات أخبار يومية. متنساش تحل الاسئلة كل يوم عشان تكسب نقط للسحب"
        image={require("../bank-illustration-transparent.png")}
      />

      <div className="current-summary">
        <div
          style={{
            textAlign: "right",
            fontSize: "4.5vw",
            fontWeight: "700",
          }}
        >
          <h3 className="marhey">{"كلام النهاردة"}</h3>
        </div>
        <Card
          style={{
            borderRadius: 10,
          }}
          sx={{
            boxShadow: 5,
          }}
        >
          <CardMedia
            component="img"
            height={"201px"}
            image={require("./sum_banner.png")}
          />
          <CardContent>
            <Typography
              variant="body1"
              style={{ textAlign: "right" }}
              dir="rtl"
              color="text.secondary"
              fontWeight={"600"}
              className="tajawal"
            >
              {moment(currentSummary?.date)
                .format("DD MMMM YYYY")
                .replace(/[٠-٩]/g, (d) => {
                  return "٠١٢٣٤٥٦٧٨٩".indexOf(d);
                })}
            </Typography>
            <Typography
              style={{ textAlign: "right" }}
              variant="body2"
              color="text.secondary"
              className="tajawal"
            >
              {
                "الملخص اليومي بيوصلك بأهم حاجات بتحصل النهاردة وازاي ليها تأثير عليك"
              }
            </Typography>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "3vh" }}
            >
              <Button
                className="current-button"
                onClick={() => handleSummaryNavigation(currentSummary)}
                style={{ margin: "auto", width: "80%" }}
              >
                <Typography
                  variant="body1"
                  fontWeight={"700"}
                  color="white"
                  className="tajawal"
                  style={{ transform: "translateY(2.5px)" }}
                >
                  اقرا ملخص النهاردة
                </Typography>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="search-container">
        <Card
          onClick={() => {
            navigate("/activity/search", {
              state: {
                isFinished: false,
              },
            });
          }}
          className="serach-card"
          sx={{
            boxShadow: 10,
            borderRadius: 5,
            cursor: "pointer",
            "&:hover": { boxShadow: 6 },
          }}
        >
          <img src={require("./testcw.jpg")} className="icon-search" />
          <Typography variant="h4" className="game_card_text marhey">
            كلمة اليوم
          </Typography>
        </Card>
      </div>
      <div>
        <div
          style={{
            textAlign: "right",
            fontSize: "4.5vw",
            fontWeight: "700",
            marginTop: "3vh",
          }}
        >
          <h3 className="marhey">{"الاسبوع"}</h3>
        </div>
        {pastSummaries.map((summary) => (
          <Card
            onClick={() => handleSummaryNavigation(summary)}
            key={summary._id}
            style={{
              // borderRadius: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
            className="summary-border"
          >
            <CardContent className="archive_base">
              <div className="chevron_base">
                <FiChevronLeft
                  // onClick={() => goBack()}
                  size={"7vw"}
                  color="#305F72"
                  // style={{ color: !canGoBack ? "grey" : "#fff" }}
                />
              </div>
              <div className="archive_content">
                <Typography
                  fontWeight={"600"}
                  dir="rtl"
                  variant="h5"
                  style={{ textAlign: "right", transform: "translateY(10px)" }}
                  color="#305F72"
                  className="tajawal"
                >
                  {moment(summary?.date).format("dddd")}
                </Typography>

                <div style={{ textAlign: "right" }}>
                  <Button variant="text" style={{ textAlign: "right" }}>
                    <Typography
                      // style={{ textDecoration: "underline" }}
                      variant="body2"
                      className="tajawal"
                      dir="rtl"
                      color="rgba(48, 95, 114, 1)"
                    >
                      {moment(summary?.date)
                        .format("DD MMMM YYYY")
                        .replace(/[٠-٩]/g, (d) => {
                          return "٠١٢٣٤٥٦٧٨٩".indexOf(d);
                        })}
                    </Typography>
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      <div style={{ height: "10vh" }} />
    </div>
  );
};

export default SummariesPage;
