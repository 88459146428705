import React from "react";
import "./games.styles.css";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar, TopNavigationBar } from "../../components";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";

const GamesPage = ({ summaries }) => {
  const navigate = useNavigate();

  const handleWordleNavigation = () => {
    navigate("/activity/wotd");
  };

  const handleWordSearchNavigation = () => {
    navigate("/activity/search");
  };

  return (
    <>
      <TopNavigationBar />
      <div className="games_container">
        {/* Card with illustration and text */}
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: 10,
            height: "120px",
          }}
        >
          {/* Left side - Illustration */}
          <CardMedia
            component="img"
            style={{
              width: "150px",
              // height: "100px",
              objectFit: "cover",
              // backgroundColor: "#ebedf5",
              // margin: "10px",
              borderRadius: "18px",
            }}
            image={require("../puzzle-illustration.jpeg")}
            alt="Illustration"
          />
          {/* Right side - Text content */}
          <CardContent sx={{ flex: 1, padding: 2 }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "right",
                marginBottom: 1,
                fontWeight: "bold",
                color: "#305F72",
              }}
              className="marhey"
            >
              ألعاب
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "right", direction: "rtl" }}
              className="tajawal"
            >
              العب ألعاب كل يوم واكسب نقط أكتر!
            </Typography>
          </CardContent>
        </Card>
        <Grid
          style={{ marginTop: 10 }}
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {/* Wordle Game Card */}
          <Grid item xs={12} md={6}>
            <Card
              onClick={handleWordleNavigation}
              sx={{
                borderRadius: 2,
                boxShadow: 2,
                padding: 3,
                textAlign: "center",
                backgroundColor: "#f0f4f8",
                cursor: "pointer",
                "&:hover": { boxShadow: 6 },
              }}
            >
              <Typography variant="h4" className="game_card_text marhey">
                كلمة اليوم
              </Typography>
            </Card>
          </Grid>

          {/* Word Search Game Card */}
          <Grid item xs={12} md={6}>
            <Card
              onClick={handleWordSearchNavigation}
              sx={{
                borderRadius: 2,
                boxShadow: 2,
                backgroundColor: "#f0f4f8",
                textAlign: "center",
                padding: 3,
                cursor: "pointer",
                "&:hover": { boxShadow: 6 },
              }}
            >
              <Typography variant="h4" className="game_card_text marhey">
                فين الكلمة
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </div>
      <BottomNavigationBar />
    </>
  );
};

export default GamesPage;
