import axios from "axios";
import { baseUrl } from "./api";

export default class SettingsApis {
  static editSettings = async (updatePayload) => {
    const token = localStorage.getItem("token");
    return axios
      .put(
        `${baseUrl}/users/settings`,
        { updatePayload },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .catch((err) => {
        throw err;
      });
  };
}
