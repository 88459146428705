import { useCallback } from "react";

// Custom hook for haptic feedback
const useHaptic = () => {
  const triggerVibration = useCallback((pattern) => {
    // Check if it's an Android device
    const isAndroid = /android/i.test(navigator.userAgent);
    // Check if it's an iOS device
    const isIOS = /iphone|ipod|ipad/i.test(navigator.userAgent);

    if (isAndroid && navigator.vibrate) {
      // For Android devices, use the Vibration API
      navigator.vibrate(pattern);
    } else if (isIOS) {
      // For iOS devices, use the Haptic API (if available)
      if (window.HapticFeedback) {
        const haptic = new window.HapticFeedback();
        switch (pattern) {
          case "light":
            haptic.selection();
            break;
          case "medium":
            haptic.impact({ style: "medium" });
            break;
          case "heavy":
            haptic.impact({ style: "heavy" });
            break;
          case "notification":
            haptic.notification({ style: "success" });
            break;
          default:
            haptic.selection(); // Fallback to selection haptic feedback
        }
      } else {
        console.warn("Haptic feedback is not supported on this iOS version.");
      }
    } else {
      console.warn("Haptic feedback is not supported on this platform.");
    }
  }, []);

  return triggerVibration;
};

export default useHaptic;
