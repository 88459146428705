import React, { useEffect, useState } from "react";
import "./summary.styles.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigationBar, TopNavigationBar } from "../../components";
import { FiX, FiXCircle } from "react-icons/fi";

const SummaryPage = ({ summaries }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const summary = location.state;

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const handleSummariesNavigation = (link) => {
    navigate(`/learn/summary/${summary._id}/quiz`, {
      state: { flashCards: summary.quiz.questions },
    });
  };

  const handleSummariesBackNavigation = () => {
    navigate("/learn/summaries");
  };
  return (
    <div className="summary-container">
      <TopNavigationBar />
      {/* <div className="header-summary">
        <div
          style={{
            backgroundColor: "#ebedf5",
            height: "7.5vw",
            width: "7.5vw",
            marginLeft: "auto",
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleSummariesBackNavigation}
        >
          <FiX style={{ color: "#305F72" }} size={"5vw"} />
        </div>
      </div>{" "} */}
      <Card style={{ borderRadius: 10, paddingBottom: "2vh" }}>
        <CardMedia
          component="img"
          style={{ paddingTop: "2vmin" }}
          height={"301px"}
          image={summary.banner}
        ></CardMedia>
        <FiX
          style={{
            color: "#305F72",
            position: "absolute",
            right: "6vw",
            top: "10vh",
            zIndex: 9999,
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
            backgroundColor: "#ebedf5",
            borderRadius: 100,
            padding: 3,
          }}
          size={"5vw"}
          onClick={handleSummariesBackNavigation}
        />
        <CardContent style={{ paddingTop: 0 }}>
          {summary.sections.map((section) => (
            <div>
              <div
                style={{
                  textAlign: "right",
                  fontSize: "4.5vw",
                  fontWeight: "700",
                  borderBottom: "0.5px solid lightgrey",
                }}
                dir="rtl"
                className="tajawal"
              >
                <h3>{section.heading}</h3>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: section.content }}
                dir="rtl"
              />
            </div>
          ))}
          <div style={{ width: "100%", textAlign: "center", marginTop: "3vh" }}>
            <Button
              className="current-button"
              style={{ margin: "auto", width: "80%" }}
              onClick={() => handleSummariesNavigation()}
            >
              <Typography
                variant="body1"
                fontWeight={"700"}
                style={{ transform: "translateY(2.5px)" }}
                color="white"
                className="tajawal"
              >
                اذهب إلى الاختبار
              </Typography>
            </Button>
          </div>
        </CardContent>
      </Card>
      <BottomNavigationBar />
    </div>
  );
};

export default SummaryPage;
